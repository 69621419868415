/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 06/17/2020
 * @Example 
 */
import { withStyles } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { compose } from "redux";
import Config from "../../../../config/index";
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";

const styles = {
  labelNumberFormat: {
    padding: "6.5px 0 !important",
    transform: "translateY(10px) !important"
  },
};

class W05F0002ShippingPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledQD: false
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const nextFormData = nextProps.formData;
    const { formData, getBaseCurrency } = this.props;
    const { disabledQD } = this.state;
    if (getBaseCurrency.BaseCurrencyID === formData.CurrencyID) {
      this.setState({ disabledQD: true });
    } else if (disabledQD === true) {
      this.setState({ disabledQD: false });
    }
    if (nextFormData.ExchangeRate !== formData.ExchangeRate) {
      this.mathDepositValue(null, null, nextFormData.ExchangeRate);
    }
  }

  onChangeCbo(fieldName, e) {
    const { mode } = this.props;
    if (mode !== "edit") return;
    const valueID = _.get(e, "value", _.get(e, "target.value", e ? e : ""));
    const { onChangeCbo, getCboDeliveryMethod, getCboPorts, getCboPaymentMethod, getCboBanks, getCboDepositStatus } = this.props;
    let valueName = null;
    switch (fieldName) {
      case "DeliveryMethodID": {
        getCboDeliveryMethod.forEach(item => {
          if (item.DeliveryMethodID === valueID) {
            valueName = item.DeliveryMethodName
          }
        });
        break;
      }
      case "PortIDFrom":
      case "PortIDTo": {
        getCboPorts.forEach(item => {
          if (item.PortID === valueID) {
            valueName = item.PortName
          }
        });
        break;
      }
      case "PaymentMethodID": {
        getCboPaymentMethod.forEach(item => {
          if (item.PaymentMethodID === valueID) {
            valueName = item.PaymentMethodName
          }
        });
        break;
      }
      case "BankCode": {
        getCboBanks.forEach(item => {
          if (item.BankCode === valueID) {
            valueName = item.BankName
          }
        });
        break;
      }
      case "DepositStatus":
        const val = getCboDepositStatus.find(rs => rs.DepositStatusID === valueID);
        valueName = val?.DepositStatusName ?? '';
        break;
      default:
        break;
    }
    if (_.isNull(valueName)) return;
    onChangeCbo(valueName, fieldName);
  }

  mathDepositValue = async (e, fieldName, exchangeRate) => {
    const { formData, onChange } = this.props;
    const { DepRate, OTotal, ExchangeRate } = formData;
    let value = DepRate || "";
    const _ExchangeRate = exchangeRate ? exchangeRate : ExchangeRate;
    if (fieldName) {
      value = _.get(e, "value", _.get(e, "target.value", e ? e : ""));
      await onChange(e, fieldName);
    }
    if (!_.isUndefined(OTotal)) {
      const resultDepAmount = value * OTotal;
      await onChange(resultDepAmount, "DepAmount")
      if (!_.isUndefined(_ExchangeRate)) {
        const resultDepCAmount = (value * OTotal) * _ExchangeRate;
        await onChange(resultDepCAmount, "DepCAmount")
      }
    }
  }

  render() {
    const { disabledQD } = this.state;
    const { formData, onChange, loading, getCboDeliveryMethod, getCboPorts, getCboBanks, classes, getCboPaymentMethod, getDecimalQuantity, getCboDepositStatus } = this.props;
    const { DeliveryMethodID, PortIDFrom, PortIDTo, BankCode, SendDate, PaymentMethodID, DepRate = "", DepAmount = "", DepCAmount = "",
      ReturnDate, DepositStatus } = formData;
    const { ExchangeRateDecimals = 0, OriginalDecimal = 0 } = getDecimalQuantity;

    return (
      <div style={{ width: "100%" }}>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Combo
                  label={Config.lang("ERP_Phuong_tien_van_chuyen")}
                  dataSource={getCboDeliveryMethod}
                  displayExpr={"DeliveryMethodName"}
                  itemRender={(e) => {
                    if (!e) return null;
                    return `${e.DeliveryMethodID} - ${e.DeliveryMethodName}`;
                  }}
                  valueExpr={"DeliveryMethodID"}
                  stylingMode={"underlined"}
                  margin={"normal"}
                  showClearButton={true}
                  disabled={loading}
                  value={DeliveryMethodID}
                  shrink={true}
                  onValueChanged={(e) => {
                    onChange(e, "DeliveryMethodID")
                    this.onChangeCbo("DeliveryMethodID", e)
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <DateBoxPicker
                  InputLabelProps={{
                    shrink: true,
                    style: { marginTop: -7 }
                  }}
                  useMaskBehavior={true}
                  label={Config.lang("Thoi_gian_giao_hang_tu_ngay")}
                  width={"100%"}
                  stylingMode={"underlined"}
                  shrink={true}
                  value={SendDate}
                  onValueChanged={e => onChange(e, "SendDate")}
                  margin={"normal"}
                  disabled={loading}
                  showClearButton={true}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <DateBoxPicker
                  InputLabelProps={{
                    shrink: true,
                    style: { marginTop: -7 }
                  }}
                  useMaskBehavior={true}
                  label={Config.lang("Thoi_gian_giao_hang_den_ngay")}
                  width={"100%"}
                  stylingMode={"underlined"}
                  shrink={true}
                  value={ReturnDate}
                  onValueChanged={e => onChange(e, "ReturnDate")}
                  margin={"normal"}
                  disabled={loading}
                  showClearButton={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Combo
                  label={Config.lang("ERP_Cang_di")}
                  dataSource={getCboPorts}
                  displayExpr={"PortName"}
                  showClearButton={true}
                  itemRender={(e) => {
                    if (!e) return null;
                    return `${e.PortID} - ${e.PortName}`;
                  }}
                  valueExpr={"PortID"}
                  stylingMode={"underlined"}
                  margin={"normal"}
                  disabled={loading}
                  value={PortIDFrom}
                  shrink={true}
                  onValueChanged={(e) => {
                    onChange(e, "PortIDFrom")
                    this.onChangeCbo("PortIDFrom", e)
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Combo
                  label={Config.lang("ERP_Cang_den")}
                  dataSource={getCboPorts}
                  displayExpr={"PortName"}
                  showClearButton={true}
                  itemRender={(e) => {
                    if (!e) return null;
                    return `${e.PortID} - ${e.PortName}`;
                  }}
                  valueExpr={"PortID"}
                  stylingMode={"underlined"}
                  margin={"normal"}
                  disabled={loading}
                  value={PortIDTo}
                  shrink={true}
                  onValueChanged={(e) => {
                    onChange(e, "PortIDTo")
                    this.onChangeCbo("PortIDTo", e)
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Combo
                  label={Config.lang("ERP_Phuong_thuc_thanh_toan")}
                  dataSource={getCboPaymentMethod}
                  displayExpr={"PaymentMethodName"}
                  showClearButton={true}
                  itemRender={(e) => {
                    if (!e) return null;
                    return `${e.PaymentMethodID} - ${e.PaymentMethodName}`;
                  }}
                  valueExpr={"PaymentMethodID"}
                  stylingMode={"underlined"}
                  margin={"normal"}
                  disabled={loading}
                  value={PaymentMethodID}
                  shrink={true}
                  onValueChanged={(e) => {
                    onChange(e, "PaymentMethodID")
                    this.onChangeCbo("PaymentMethodID", e)
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Combo
                  label={Config.lang("ERP_Ngan_hang")}
                  dataSource={getCboBanks}
                  displayExpr={"BankName"}
                  showClearButton={true}
                  itemRender={(e) => {
                    if (!e) return null;
                    return `${e.BankCode} - ${e.BankName}`;
                  }}
                  valueExpr={"BankCode"}
                  stylingMode={"underlined"}
                  margin={"normal"}
                  disabled={loading}
                  value={BankCode}
                  shrink={true}
                  onValueChanged={(e) => {
                    onChange(e, "BankCode")
                    this.onChangeCbo("BankCode", e)
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <label className={`MuiFormLabel-root ${classes.labelNumberFormat}`}>
                  {Config.lang("ERP_Ty_le_deposit")}
                </label>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  thousandSeparator={true}
                  isNumericString={true}
                  decimalScale={ExchangeRateDecimals}
                  value={String(!_.isNull(DepRate) ? DepRate : 0) || ""}
                  onValueChange={e => this.mathDepositValue(e, "DepRate")}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <label className={`MuiFormLabel-root ${classes.labelNumberFormat}`}>
                  {Config.lang("ERP_Thanh_tien_deposit")}
                </label>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  thousandSeparator={true}
                  isNumericString={true}
                  decimalScale={ExchangeRateDecimals}
                  value={String(!_.isNull(DepAmount) ? DepAmount : 0) || ""}
                  onValueChange={e => onChange(e, 'DepAmount')}
                />
              </Col>
            </Row>
            <Row>
              {!disabledQD &&
                <Col xs={12} sm={6} md={4} lg={4}>
                  <label className={`MuiFormLabel-root ${classes.labelNumberFormat}`}>
                    {Config.lang("ERP_Thanh_tien_deposit_QD")}
                  </label>
                  <NumberFormat
                    fullWidth
                    customInput={TextField}
                    thousandSeparator={true}
                    isNumericString={true}
                    disabled={true}
                    decimalScale={OriginalDecimal}
                    value={String(!_.isNull(DepCAmount) ? DepCAmount : 0) || ""}
                  />
                </Col>}
              <Col xs={12} sm={6} md={4} lg={4}>
                <Combo
                  label={Config.lang("Trang_thai_deposit")}
                  dataSource={getCboDepositStatus}
                  displayExpr={"DepositStatusName"}
                  valueExpr={"DepositStatusID"}
                  showClearButton={true}
                  stylingMode={"underlined"}
                  margin={"normal"}
                  disabled={loading}
                  value={DepositStatus}
                  shrink={true}
                  onValueChanged={(e) => {
                    onChange(e, "DepositStatus");
                    this.onChangeCbo("DepositStatus", e);
                  }}

                />
              </Col>
            </Row>
          </Col>
          <Col md={0} lg={3}></Col>
        </Row>
      </div>
    );
  }
}

W05F0002ShippingPayment.propTypes = {
  loading: PropTypes.bool,
  mode: PropTypes.string,
  formData: PropTypes.object,
  errorForm: PropTypes.object,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  onChangeCbo: PropTypes.func,
};

export default compose(
  connect(state => ({
    getCboDeliveryMethod: state.W05F0002.getCboDeliveryMethod,
    getCboPaymentMethod: state.W05F0002.getCboPaymentMethod,
    getCboPorts: state.W05F0002.getCboPorts,
    getCboBanks: state.W05F0002.getCboBanks,
    getBaseCurrency: state.W05F0002.getBaseCurrency,
    getDecimalQuantity: state.W05F0002.getDecimalQuantity,
    getCboDepositStatus: state.W05F0002.getCboDepositStatus
  })),
  withStyles(styles, { withTheme: true }))(W05F0002ShippingPayment);