/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 06/17/2020
 * @Example
 */
import moment from 'moment';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { DatePicker, TextInput, NumberInput } from 'diginet-core-ui/components';

class W05F0002OtherInfo extends Component {
  numberFormat = (number) => {
    let removeComma = number;
    if (typeof (number) === "string" && number.indexOf(",") !== -1) {
      removeComma = number.replace(/,/gi, '');
    }
    return !removeComma || removeComma === "NaN" ? 0 : removeComma;
  };

  renderSup = (dataList) => {
    const { onChange, formData } = this.props;
    const supColumn = dataList.map((item, idx) => {
      const { DataType, FieldName, Caption } = item;
      return (
        <Col xs={12} sm={6} md={6} lg={6} style={{ minHeight: 74 }} key={idx}>
          {DataType === "datetime" ?
            <DatePicker
              label={Caption}
              displayFormat="DD/MM/YYYY"
              value={moment(formData[FieldName], moment.ISO_8601, true).isValid() ? formData[FieldName] : ""}
              clearAble
              onChange={e => onChange(e, FieldName)}
              returnFormat={'YYYY-MM-DD'}
            />
            :
            DataType.search("decimail") !== 0 && DataType.search("decimal") !== 0 ?
              <TextInput
                label={Caption}
                value={formData[FieldName]}
                onChange={(e) => onChange(e, FieldName)}
              /> :
              <NumberInput
                label={Caption}
                value={formData[FieldName]}
                onChange={(e) => onChange(e, FieldName)}
              />}
        </Col>
      )
    });
    return supColumn;
  }

  render() {
    const { getMasterSupplement } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              {getMasterSupplement && this.renderSup(getMasterSupplement)}
            </Row>
          </Col>
          <Col md={0} lg={3}></Col>
        </Row>
      </div>
    );
  }
}

W05F0002OtherInfo.propTypes = {
  loading: PropTypes.bool,
  mode: PropTypes.string,
  formData: PropTypes.object,
  onChange: PropTypes.func,
};

export default compose(
  connect(state => ({
    getMasterSupplement: state.W05F0002.getMasterSupplement,
  })))(W05F0002OtherInfo);