import { FormLabel as Label, Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { Col, FormGroup, Image, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from '../../../../redux/general/general_actions';
import * as w05f0001Actions from '../../../../redux/W0X/W05F0001/W05F0001_actions';
import ButtonGeneral from "../../../common/button/button-general";
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import Status from "../../../common/status/status";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Filter from "../../../filter/filter";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import PopoverAction from "../../../grid-container/popover-action";
import W05F0001Tabs from './W05F0001Tabs';
import W05F0001UpdateInfoCancel from './W05F0001UpdateInfoCancel';
import { PaperView, AddNote } from 'diginet-core-ui/icons';

const styles = {
    divIcon: {
        right: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 120,
        height: 36,
        padding: 0,
        zIndex: 999,
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        listStyle: 'none',
        marginTop: -30,
        borderRadius: 4,
    },
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
        '&:last-child': {
            marginRight: 0
        }
    },
    hiddenList: {
        opacity: 0,
        visibility: 'hidden'
    },
};
class W05F0001 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalDataGrid: 0,
            isPermission: 0,
            ContractNo: "",
            contractTypesChosen: "",
            gridLoaded: false,
            showFormItem: false,
            loadingCboEmployees: false,
            loadingCboTransTypes: false,
            loadingCboListObjects: false,
            updateInfoCancelStatus: false,
            loadingCboContractTypes: false,
            error: {},
            currentRowSelected: {},
            dataCboListObjects: {
                rows: [],
                total: 0
            },
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            ObjectID: [],
            EmployeeID: [],
            listContract: [],
            cboContractTypes: [],
            dateFrom: moment().format("YYYY-MM-DD"),
            dateTo: moment().format("YYYY-MM-DD"),
        };
        this.filterContractByStatus = {
            StrSearch: "",
            ContractNo: "",
            ObjectID: "",
            EmployeeID: "",
            ContractTypeID: "",
            Language: Config.language || "84",
            EStatus: "all",
            selectedDate: 7, // 7 Default giá trị là Year
            DateFrom: moment().startOf("year").format("YYYY-MM-DD"),
            DateTo: moment().endOf("year").format("YYYY-MM-DD"),
            TransTypeID: "",
            skip: 0,
            limit: 10
        };
        this.filterCboListObject = {
            StrSearch: "",
            ObjectTypeID: "KH",
            skip: 0,
            limit: 10
        };
        this.filterCboEmployees = {
            StrSearch: "",
            skip: 0,
            limit: 10
        };
        this.popoverGrid = null;
        this.toolFilter = null;
        this.dataDayByCombo = [
            {
                id: 1,
                fieldName: Config.lang("ERP_Hom_nay"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
            {
                id: 2,
                fieldName: Config.lang("ERP_Hom_qua"),
                dateFrom: moment().subtract(1, 'day').format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, 'day').format("YYYY-MM-DD")
            },
            {
                id: 3,
                fieldName: Config.lang("ERP_Tuan_nay"),
                dateFrom: moment().startOf('week').format("YYYY-MM-DD"),
                dateTo: moment().endOf('week').format("YYYY-MM-DD")
            },
            {
                id: 4,
                fieldName: Config.lang("ERP_Tuan_truoc"),
                dateFrom: moment().subtract(1, 'week').startOf('week').format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, 'week').endOf('week').format("YYYY-MM-DD")
            },
            {
                id: 5,
                fieldName: Config.lang("ERP_Thang_nay"),
                dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 6,
                fieldName: Config.lang("ERP_Thang_truoc"),
                dateFrom: moment().subtract(1, 'months').startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, 'months').endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 7,
                fieldName: Config.lang("ERP_Nam_nay"),
                dateFrom: moment().startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 8,
                fieldName: Config.lang("ERP_Nam_truoc"),
                dateFrom: moment().subtract(1, 'years').startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, 'years').endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 9,
                fieldName: Config.lang("ERP_Chon_thoi_gian"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
        ];
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W05F0001", (isPer) => {
            this.setState({ isPermission: isPer });
        });
    };

    showFormSearchDay = (key, data) => {
        if (!key || !data || !data.value || _.isEmpty(this.dataDayByCombo)) return false;
        const checked = this.dataDayByCombo.find(value => value.id === data.value);
        const showFormItem = checked.id === 9; // 9 Là tùy Chọn Ngày
        this.filterContractByStatus.selectedDate = data.value;
        this.filterContractByStatus.DateFrom = checked.dateFrom;
        this.filterContractByStatus.DateTo = checked.dateTo;
        this.setState({ showFormItem });
    };

    handleFilterChange(key, data) {
        if (!key) return false;
        switch (key) {
            case "id":
                this.showFormSearchDay(key, data);
                break;
            case "dateFrom":
                this.setState({ dateFrom: data.value });
                this.filterContractByStatus.DateFrom = data.value;
                break;
            case "dateTo":
                this.setState({ dateTo: data.value });
                this.filterContractByStatus.DateTo = data.value;
                break;
            case "EmployeeID":
                this.setState({ EmployeeID: data.value });
                this.filterContractByStatus.EmployeeID = !_.isNull(data.value) ? data.value.EmployeeID : "";
                break;
            case "ObjectID":
                this.setState({ cbObject: data.value });
                this.filterContractByStatus.ObjectID = !_.isNull(data.value) ? data.value.ObjectID : "";
                break;
            case "ContractNo":
                this.setState({ ContractNo: data.target.value })
                break;
            case "ContractTypeID":
                this.filterContractByStatus.ContractTypeID = data.value || "";
                break;
            case "TransTypeID":
                this.filterContractByStatus.TransTypeID = data.value || "";
                break;
            // case "EStatus":
            //     this.filterContractByStatus.EStatus = data.value;
            //     break;
            default:
                break;
        }
    }

    loadCboContractTypes = () => {
        this.setState({ loadingCboContractTypes: true });
        this.props.w05f0001Actions.getCboContractTypes({}, (error) => {
            this.setState({ loadingCboContractTypes: false });
            if (error) {
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    loadCboTransTypes = () => {
        this.setState({ loadingCboTransTypes: true });
        this.props.w05f0001Actions.getCboTransType((error, data) => {
            this.setState({ loadingCboTransTypes: false });
            if (error) {
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const { StrSearch, skip, limit } = this.filterCboEmployees;
        const params = {
            StrSearch,
            skip,
            limit
        };
        this.setState({ loadingCboEmployees: true });
        this.props.w05f0001Actions.getCboEmployees(params, (error, data) => {
            this.setState({ loadingCboEmployees: false });
            if (error) {
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data.rows || [];
                const total = data.total || 0;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboListObjects = (isReset) => {
        const { skip, limit, ObjectTypeID, StrSearch } = this.filterCboListObject;
        const params = {
            ObjectTypeID,
            StrSearch,
            skip,
            limit
        };
        this.setState({ loadingCboListObjects: true });
        this.props.w05f0001Actions.getCboListObjects(params, (error, data) => {
            this.setState({ loadingCboListObjects: false });
            if (error) {
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListObjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListObjects: {
                        rows: isReset ? rows : dataCboListObjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadListContracts = () => {
        const { ContractNo } = this.state;
        const { ObjectID, ContractTypeID, TransTypeID, EStatus, skip, limit, Language, EmployeeID, DateFrom, DateTo, StrSearch } = this.filterContractByStatus;
        const params = {
            Language,
            StrSearch,
            ContractNo,
            ObjectID,
            ContractTypeID,
            TransTypeID,
            EStatus: EStatus !== "all" ? EStatus : "",
            EmployeeID,
            DateFrom,
            DateTo,
            skip,
            limit
        };
        this.setState({ gridLoaded: true, error: {} });
        this.props.w05f0001Actions.getListContracts(params, (error, data) => {
            this.setState({ gridLoaded: false });
            if (error) {
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                this.setState({ listContract: data.rows, totalDataGrid: data.total });
            }
        });
    };

    loadAmountOfContractByStatus = () => {
        const { ContractNo } = this.state;
        const { StrSearch, Language, ObjectID, ContractTypeID, EStatus, DateFrom, DateTo, EmployeeID } = this.filterContractByStatus;
        const params = {
            Language,
            ContractNo,
            ObjectID,
            ContractTypeID,
            EStatus: EStatus === "all" ? "" : EStatus,
            EmployeeID,
            DateFrom,
            DateTo,
            StrSearch,
        };
        this.props.w05f0001Actions.getAmountOfContractByStatus(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    onChangePage = (page) => {
        this.filterContractByStatus.skip = page * this.filterContractByStatus.limit;
        this.loadListContracts();
    };

    onChangePerPage = (per) => {
        this.filterContractByStatus.skip = 0;
        this.filterContractByStatus.limit = per;
        this.loadListContracts();
    };

    onSearch = (e) => {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.filterContractByStatus.StrSearch = e.target.value;
            this.filterContractByStatus.skip = 0;
            this.filterContractByStatus.limit = 10;
            this.loadListContracts();
        }, 300)
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.isPermission <= 0) return;
        const { getCboTransTypes } = this.props;
        await this.loadAmountOfContractByStatus();
        await this.loadListContracts();
        if (_.isEmpty(getCboTransTypes)) this.loadCboTransTypes();
    };

    onFilter = () => {
        this.loadAmountOfContractByStatus();
        this.loadListContracts(); //TRUE đóng dropdown
        if (this.toolFilter) this.toolFilter.instance.close(); // Đóng dropdown
    };

    renderTextColor = (e) => {
        const checkMartIcon = require("../../../../assets/images/W05F0001/checkmark.svg");
        const dotIcon = require("../../../../assets/images/W05F0001/dot.svg");
        const bookIcon = require("../../../../assets/images/W05F0001/book.svg");
        const arrowRight = require("../../../../assets/images/W05F0001/arrow-right.svg");
        if (e && e.row) {
            const { data } = e.row;
            let color = "";
            let icon = bookIcon;
            switch (Number(data.EStatus)) {
                case 3:
                    color = "info";
                    icon = dotIcon;
                    break;
                case 2:
                    color = "success";
                    icon = checkMartIcon;
                    break;
                case 4:
                    color = "warning";
                    icon = arrowRight;
                    break;
                default:
                    break;
            }
            const status = {
                AppStatusID: data.EStatus,
                AppStatusName: data.EStatusName,
                AppStatusColor: color,
                AppStatusIcon: <Image src={icon} />
            };
            return <Status data={status} />
        }
    };

    saveHistory = async (ContractID, ContractNo) => {
        let el = {};
        let data = [];
        let status = "";
        el.codeID = ContractID;
        el.formID = "W05F0001";
        el.linkedTrans = "";
        el.oldValue = ContractNo;
        el.newValue = "";
        el.type = "";
        el.description84 = "";
        el.description01 = "";
        el.action = 3;
        data.push(el);
        const params = {
            attributes: JSON.stringify(data)
        };
        await this.props.w05f0001Actions.addHistory(params, (error) => {
            status = error ? 400 : 200
        });
        return status;
    };

    onDelete = (currentRowSelected) => {
        if (!_.isEmpty(currentRowSelected)) {
            const { ContractID, ContractNo, IsDeployed } = currentRowSelected;
            if (IsDeployed === 1) {
                Config.popup.show("INFO", Config.lang("Hop_dong_da_co_dot_trien_khai_ban_khong_the_xoa"));
            } else {
                Config.popup.show("YES_NO", Config.lang("ERP_Ban_co_chac_muon_xoa?"), () => {
                    const params = { ContractID };
                    this.props.w05f0001Actions.deleteItemContract(params, async (errors, data) => {
                        // this.handleClose("popOverAction")
                        if (errors) {
                            const message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                            Config.popup.show("INFO", message);
                            return false;
                        } else {
                            if (data.Status) {
                                Config.popup.show("INFO", data.Message);
                            } else {
                                Config.notify.show("success", data.Message, 2000);
                            }
                            const historyStatus = await this.saveHistory(ContractID, ContractNo);
                            if (historyStatus !== 200) {
                                Config.popup.show("INFO", Config.lang("ERP_Co_loi_khi_luu"));
                                return false;
                            }
                        }
                        this.loadAmountOfContractByStatus();
                        this.loadListContracts();
                    })
                })
            }
        }
    };

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    popupActionBar = () => {
        const { currentRowSelected } = this.state;
        const { classes, permission } = this.props;
        return (
            <>
                <PopoverAction
                    reference={ref => this.popoverGrid = ref}
                    position={"right"}
                    maxWidth={300}
                    deferRendering={false}
                >
                    <div style={{ padding: 4 }}>
                        <Tooltip title={Config.lang("ERP_Sua")}
                        >
                            <IconButton
                                disabled={permission < 3}
                                aria-label={"edit"}
                                className={classes.divIconItem}
                                onClick={() => this.redirectScreen("edit", currentRowSelected)}
                            >
                                <Icons name={"edit"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Xoa")}>
                            <IconButton
                                disabled={permission < 4}
                                aria-label={"delete"}
                                className={classes.divIconItem}
                                onClick={() => {
                                    if (this.popoverGrid) this.popoverGrid.instance.hide();  // Đóng popup Action
                                    this.onDelete(currentRowSelected)
                                }}
                            >
                                <Icons name={"delete"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Sao_chep")}>
                            <IconButton
                                disabled={permission < 2}
                                aria-label={"copy"}
                                className={classes.divIconItem}
                                onClick={() => this.redirectScreen("copy", currentRowSelected)}
                            >
                                <Icons name={"copy"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Cap_nhat_thong_tin_huy")}>
                            <IconButton
                                disabled={permission < 2}
                                aria-label={"update information cancel"}
                                className={classes.divIconItem}
                                onClick={() => {
                                    if (this.popoverGrid) this.popoverGrid.instance.hide(); // Đóng popup Action
                                    this.setState({ updateInfoCancelStatus: true });
                                }}
                            >
                                <Icons name={"update-info-cancel"} />
                            </IconButton>
                        </Tooltip>
                        {!(Number(currentRowSelected.EStatus) !== 2 && Number(currentRowSelected.EStatus) !== 3) &&
                            <Tooltip title={Config.lang("Lap_phu_luc")}>
                                <IconButton
                                    className={classes.divIconItem}
                                    onClick={() => this.redirectScreen("appendix", currentRowSelected)}
                                >
                                    <AddNote />
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title={Config.lang("Xem_hop_dong_goc")}>
                            <IconButton
                                className={classes.divIconItem}
                                onClick={() => this.redirectScreen("view_contract", currentRowSelected)}
                            >
                                <PaperView />
                            </IconButton>
                        </Tooltip>
                    </div>
                </PopoverAction>
            </>
        );
    };

    handleClick = (e, currentRowSelected) => {
        const target = e.currentTarget;
        this.setState({
            popOverAction: e.currentTarget,
            currentRowSelected
        });
        if (this.popoverGrid) this.popoverGrid.instance.show(target);
    };

    renderIconAction = e => {
        const { data } = e.row;
        return (
            <IconButton
                aria-label={"view"}
                aria-describedby={data.ContractID}
                className={this.props.classes.divIconItem}
                onClick={(e) => this.handleClick(e, data)}
                disabled={false}
            >
                <MoreVertIcon />
            </IconButton>
        );
    };

    renderButtonAction = (e, mode) => {
        return (
            mode === "mobile" ?
                this.renderIconAction(e)
                : <GridActionBar>
                    {this.renderIconAction(e)}
                </GridActionBar>
        );
    };

    renderItemCboEmployee = (e) => {
        const { data } = e;
        if (data) {
            return (
                <div className={"display_row align-center"}>
                    <UserImage data={data} allowHover={false} />
                    <div className={"cbo-employee-name"}>
                        <UserName data={data} allowHover={false} />
                    </div>
                </div>
            );
        }
    };

    onLoadDataFilter = () => {
        this.loadCboContractTypes();
        this.loadCboEmployees();
        this.loadCboListObjects();
    };

    renderFilters = () => {
        const { getCboContractTypes, getCboTransTypes } = this.props;
        const { EmployeeID, ContractNo, dateFrom, dateTo } = this.state;
        const { ObjectID, ContractTypeID, selectedDate, DateFrom, DateTo, TransTypeID } = this.filterContractByStatus;
        const {
            dataCboEmployees,
            dataCboListObjects,
            loadingCboListObjects,
            loadingCboEmployees,
            error,
            showFormItem,
            loadingCboContractTypes,
            loadingCboTransTypes,
        } = this.state;
        const dropDownHeight = Config.getHeightGrid() > 520 ? null : Config.getHeightGrid() - 320;
        return (
            <Filter
                dropdownProps={{ ref: ref => this.toolFilter = ref }}
                onTextChanged={this.onSearch}
                placeholder={Config.lang("ERP_Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={Config.storeDataSoureDevExtreme(this.dataDayByCombo)}
                                            valueExpr={"id"}
                                            stylingMode={"outlined"}
                                            label={Config.lang("ERP_Chon_thoi_gian")}
                                            displayExpr={"fieldName"}
                                            value={selectedDate || 7}
                                            onValueChanged={e => this.handleFilterChange("id", e)}
                                        />
                                    </Col>
                                </Row>
                                {showFormItem && <Row>
                                    <Col xs={12} sm={6} md={6} lg={6} >
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("ERP_Tu")}</Label>
                                            <DateBoxPicker
                                                error={error && error['DateFrom']}
                                                max={dateTo}
                                                placeholder={"DD/MM/YYYY"}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={DateFrom}
                                                onValueChanged={e => this.handleFilterChange("dateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("ERP_Den")}</Label>
                                            <DateBoxPicker
                                                error={error && error["DateTo"]}
                                                min={dateFrom}
                                                placeholder={"DD/MM/YYYY"}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={DateTo}
                                                onValueChanged={e => this.handleFilterChange("dateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div>
                                            <TextField
                                                label={Config.lang("ERP_So_hop_dong")}
                                                variant={"outlined"}
                                                value={ContractNo}
                                                onChange={(e) => this.handleFilterChange("ContractNo", e)}
                                                fullWidth
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboListObjects}
                                            skip={this.filterCboListObject.skip}
                                            limit={this.filterCboListObject.limit}
                                            displayExpr={"ObjectNameU"}
                                            keyExpr={"ObjectID"}
                                            valueExpr={"ObjectID"}
                                            value={ObjectID}
                                            height={dropDownHeight}
                                            loading={loadingCboListObjects}
                                            stylingMode={"outlined"}
                                            label={Config.lang("ERP_Khach_hang")}
                                            showClearButton={true}
                                            onValueChanged={(e) => this.handleFilterChange("ObjectID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboListObject.StrSearch = e.target.value;
                                                this.filterCboListObject.skip = 0;
                                                this.loadCboListObjects(true);
                                            }}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return `${data.ObjectID} - ${data.ObjectNameU}`;
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboListObject.skip = e.skip;
                                                this.filterCboListObject.limit = e.limit;
                                                this.loadCboListObjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("ERP_Loai_hop_dong")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboContractTypes)}
                                            displayExpr={"VoucherTypeName"}
                                            valueExpr={"ContractTypeID"}
                                            loading={loadingCboContractTypes}
                                            showClearButton={true}
                                            value={ContractTypeID}
                                            itemRender={(e) => {
                                                if (!e) return null;
                                                return `${e.ContractTypeID} - ${e.VoucherTypeName}`;
                                            }}
                                            onValueChanged={e => this.handleFilterChange("ContractTypeID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("ERP_Loai_nghiep_vu")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboTransTypes)}
                                            displayExpr={"TransTypeName"}
                                            valueExpr={"TransTypeID"}
                                            loading={loadingCboTransTypes}
                                            showClearButton={true}
                                            value={TransTypeID}
                                            onValueChanged={e => this.handleFilterChange("TransTypeID", e)}
                                            itemRender={(e) => {
                                                if (!e) return null;
                                                const line = !_.isEmpty(e.TransTypeName) ? "-" : "";
                                                return `${e.TransTypeID} ${line} ${e.TransTypeName}`;
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            height={"100%"}
                                            value={EmployeeID}
                                            loading={loadingCboEmployees}
                                            stylingMode={"outlined"}
                                            label={Config.lang("ERP_Nguoi_lap")}
                                            showClearButton={true}
                                            onValueChanged={(e) => this.handleFilterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.StrSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            itemRender={this.renderItemCboEmployee}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("ERP_Tim_kiem")}
                                    size={"large"}
                                    typeButton={"search"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onFilter}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    getIdEstatus = (data) => {
        if (data) {
            this.filterContractByStatus.EStatus = data;
            this.filterContractByStatus.skip = 0;
            this.loadListContracts();
        }
    };

    redirectScreen = async (mode, currentRowSelected = {}) => {
        const { contractTypesChosen, isPermission } = this.state;
        const { getCboTransTypes } = this.props;
        let screen = "";
        let Mode = 0;
        switch (mode) {
            case "add":
                screen = "W05F0002";
                this.setState({ modalContractTypes: true, mode: "add" });
                break;
            case "addFinal":
                screen = "W05F0002";
                this.setState({
                    modalContractTypes: false,
                    contractTypesChosenFinal: contractTypesChosen,
                    contractTypesChosen: ""
                });
                break;
            case "view":
                screen = "W05F0003";
                break;
            case "edit":
                screen = "W05F0002";
                break;
            case "copy":
                screen = "W05F0002";
                break;
            case "appendix":
                screen = "W05F0009";
                break;
            case "view_contract":
                screen = "W05F0003";
                Mode = 1;
                break;
            default:
                break;
        }
        if (mode === "add") return;
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: {
                mode: mode === 'appendix' ? 'add' : mode,
                cboContractTypes: getCboTransTypes,
                contractTypesChosen: contractTypesChosen,
                isPermission: isPermission,
                Mode,
                ...(!_.isEmpty(currentRowSelected) ? {
                    ContractID: currentRowSelected.ContractID,
                    ContractNo: currentRowSelected.ContractNo
                } : {})
            }
        });
    };

    onCloseModal = () => {
        this.setState({
            modalContractTypes: false,
            contractTypesChosen: ""
        });
    };

    renderNumberFormat = (e) => {
        if (!e) return false;
        const { data } = e.row;
        const { column } = e;
        const decimalValue = data[column.dataField];
        if (_.isUndefined(decimalValue)) return;
        const limitDecimal = 0;
        return <NumberFormat decimalScale={limitDecimal} value={decimalValue} displayType={"text"} thousandSeparator={true} />
    };

    render() {
        const { listContract, gridLoaded, totalDataGrid, isPermission, modalContractTypes, contractTypesChosen,
            mode, updateInfoCancelStatus, currentRowSelected } = this.state;
        const { limit, skip } = this.filterContractByStatus;
        const { getAmountOfContract, getCboTransTypes } = this.props;
        if (!isPermission) return null;
        return (
            <>
                {updateInfoCancelStatus &&
                    <W05F0001UpdateInfoCancel
                        currentRowSelected={currentRowSelected}
                        onCloseUpdateInfoCancelModal={() => this.setState({ updateInfoCancelStatus: false })}
                    />}
                {mode === "add" &&
                    <Modal open={modalContractTypes}
                        maxWidth={"sm"}
                        fullWidth={true}
                        position={'center'}>
                        <Modal.Title disableTypography>
                            <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                                <div className={"display_row align-center"}>
                                    <Typography variant={"h6"} className={"mgr10 text-uppercase"}>{Config.lang("ERP_Nghiep_vu")}</Typography>
                                </div>
                                <IconButton
                                    aria-label={"close"}
                                    size={"small"}
                                    onClick={this.onCloseModal}
                                >
                                    <Icons name={"cancel_filled"} />
                                </IconButton>
                            </div>
                        </Modal.Title>
                        <Modal.Content>
                            <Row>
                                <Col sm={3} style={{ transform: 'translateY(5px)' }}>
                                    {Config.lang("ERP_Loai_nghiep_vu")}<span style={{ color: 'red' }}>&nbsp;*</span>
                                </Col>
                                <Col sm={9}>
                                    <Combo
                                        dataSource={Config.storeDataSoureDevExtreme(getCboTransTypes)}
                                        valueExpr={"TransTypeID"}
                                        stylingMode={"underlined"}
                                        margin={"none"}
                                        disabled={gridLoaded}
                                        value={contractTypesChosen}
                                        shrink={true}
                                        displayExpr={(e) => {
                                            if (!e) return null;
                                            const line = !_.isEmpty(e.TransTypeName) ? "-" : "";
                                            return `${e.TransTypeID} ${line} ${e.TransTypeName}`;
                                        }}
                                        itemRender={(e) => {
                                            if (!e) return null;
                                            const line = !_.isEmpty(e.TransTypeName) ? "-" : "";
                                            return `${e.TransTypeID} ${line} ${e.TransTypeName}`;
                                        }}
                                        onValueChanged={(e) => this.setState({ contractTypesChosen: e.value })}
                                    />
                                </Col>
                            </Row>
                            <ButtonGeneral name={Config.lang("ERP_Dong_y")}
                                color={"primary"}
                                variant={"contained"}
                                disabled={gridLoaded || !contractTypesChosen}
                                style={{ textTransform: 'uppercase', float: 'right', marginTop: 12 }}
                                size={"large"}
                                onClick={() => this.redirectScreen("addFinal")} />
                        </Modal.Content>
                    </Modal>}
                <FormGroup>
                    <ActionToolbar allwaysTop title={Config.lang("ERP_Danh_sach_hop_dong_ban")}>
                        <ButtonGeneral
                            disabled={isPermission < 2}
                            name={Config.lang("ERP_Them")}
                            typeButton={"add"}
                            color={"primary"}
                            variant={"custom"}
                            style={{ textTransform: 'uppercase' }}
                            onClick={() => this.redirectScreen("add")}
                        />
                    </ActionToolbar>
                </FormGroup>
                {getAmountOfContract.length > 0 &&
                    <W05F0001Tabs
                        funcGetEStatus={this.getIdEstatus}
                        tabStatus={getAmountOfContract}
                    />
                }
                <div className={"hidden"}>{this.renderFilters()}</div>
                <GridContainer
                    totalItems={totalDataGrid}
                    dataSource={listContract}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    loading={gridLoaded}
                    typePaging={"remote"}
                    showBorders={false}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    onDbCellClick={e => {
                        if (e.data) this.redirectScreen("view", e.data)
                    }}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                    height={Config.getHeightGrid() - 63}
                    onContextMenuPreparing={e => {
                        if (e?.row?.rowType === "data") {
                            const text = e.row.cells[e.columnIndex].text;
                            navigator.clipboard.writeText(text);
                            Config.notify.show('success', Config.lang("Da_sao_chep"), 2000);
                            e.event.preventDefault();
                        }
                    }}
                >
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Hanh_dong")}
                        alignment={"center"}
                        visible={Config.isMobile}
                        cellRender={(e) => this.renderButtonAction(e, "mobile")}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_So_hop_dong")}
                        dataField={"ContractNo"}
                        width={200}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Khach_hang")}
                        dataField={"ObjectNameU"}
                        width={380}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Ngay_ky")}
                        dataField={"ContractDate"}
                        alignment={"center"}
                        width={150}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Nguoi_lap")}
                        dataField={"EmployeeName"}
                        width={200}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang('ERP_Loai_hop_dong')}
                        dataField={"ContractTypeName"}
                        width={200}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Trang_thai")}
                        dataField={"EStatusName"}
                        cellRender={this.renderTextColor}
                        width={180}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Loai_tien")}
                        dataField={"CurrencyID"}
                        width={90}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Ty_gia")}
                        dataField={"ExchangeRate"}
                        alignment={"right"}
                        dataType={"number"}
                        width={90}
                        cellRender={this.renderNumberFormat}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Ngay_bat_dau")}
                        dataField={"StartDate"}
                        width={150}
                        alignment={"center"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                    />
                    <Column
                        allowEditing={false}
                        caption={Config.lang("ERP_Ngay_ket_thuc")}
                        dataField={"EndDate"}
                        width={150}
                        alignment={"center"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                    />
                    <Column
                        width={140}
                        allowEditing={false}
                        alignment={"center"}
                        dataField={"IsDeployed"}
                        caption={Config.lang("ERP_Da_trien_khai")}
                        cellRender={(e) => {
                            const { data } = e;
                            return <Checkbox
                                label={""}
                                // disabled={false}
                                checked={!!data.IsDeployed}
                                color={"primary"}
                                style={{ padding: 0 }}
                            // onChange={(chk) => {
                            //     e.component.cellValue(e.rowIndex, "IsDeployed", chk.target.checked ? 1 : 0)
                            //     e.component.saveEditData();
                            // }}
                            />
                        }}
                    />
                    <Column
                        allowEditing={false}
                        alignment={"right"}
                        fixed={true}
                        fixedPosition={"right"}
                        visible={!Config.isMobile}
                        cellRender={(e) => this.renderButtonAction(e, "desktop")}
                    />
                    {listContract.length > 0 && this.popupActionBar()}
                </GridContainer>
            </>
        );
    }
}

W05F0001.propsTypes = {
    w05f0001Actions: PropTypes.any
};

export default compose(connect(state => ({
    getCboTransTypes: state.W05F0001.getCboTransTypes,
    getCboContractTypes: state.W05F0001.getCboContractTypes,
    getAmountOfContract: state.W05F0001.getAmountOfContract
}),
    dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w05f0001Actions: bindActionCreators(w05f0001Actions, dispatch)
    }),
), withStyles(styles))(W05F0001);