/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/15/2020
 * @Example
 */
import { Accordion as ExpansionPanel, AccordionDetails as ExpansionPanelDetails, AccordionSummary as ExpansionPanelSummary, Tab, Tabs, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import { LoadPanel } from "devextreme-react";
import _ from "lodash";
import moment from 'moment';
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W05F0002Actions from "../../../../redux/W0X/W05F0002/W05F0002_actions";
import Attachments from "../../../common/attachments/attachments";
import ButtonGeneral from "../../../common/button/button-general";
import { Combo, MForm } from "../../../common/form-material";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import W05F0002GeneralInfo from "./W05F0002GeneralInfo";
import W05F0002Inventory from "./W05F0002Inventory";
import W05F0002ObjectCustomer from "./W05F0002ObjectCustomer";
import W05F0002OtherInfo from "./W05F0002OtherInfo";
import W05F0002ShippingPayment from "./W05F0002ShippingPayment";
const styles = theme => {
    return {
        iconArrowCollapse: {
            fontSize: 16,
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            justifyContent: 'center',
            height: 24,
            width: 24,
            marginRight: 8,
        },
        collapseBoardContent: {
            padding: '0 18px 32px',
            borderRadius: 4
        },
        heading: {
            fontSize: 12,
            fontWeight: 'bold',
            color: '#0095FF',
            textTransform: 'uppercase',
            transform: 'translateY(3px)'
        },
        collapseBoard: {
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            marginBottom: '24px !important'
        },
        ExpansionPanelSummary: {
            padding: '0 18px',
            backgroundColor: '#F7F9FC',
            width: '100%',
            '& .MuiAccordionSummary-content': {
                margin: "12px 0 !important"
            },
            '&.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        yesNoContent: {
            margin: '12px 0 24px 0'
        }
    };
};

class W05F0002 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSave: false,
            uploading: false,
            isUpdated: false,
            formLoading: false,
            loadApiStatus: true,
            expandedFour: false,
            expandedFirst: true,
            expandedSecond: true,
            expandedThird: false,
            disableSaveBtn: false,
            isEmptyInventory: false,
            modalContractTypes: false,
            modalContractTypesYesNo: false,
            isQuotation: null,
            quotationFinish: null,
            tabKey: 0,
            contractTypesChosen: "",
            formData: {
                OTotal: 0,
                Ctotal: 0,
                Employee: null,
                ContractDate: moment().format("YYYY-MM-DD"), // Default Date
                StartDate: moment().format("YYYY-MM-DD"), // Default Date
                ExceedContract: ''
            },
            errors: {},
            contractTypesData: {},
            fieldName: [],
            fieldValue: [],
            getAttachment: [],
            getInventoryList: [],
            activeInventoryColumn: [],
            activeInventoryColumnName: [],
        };
        this.Language = Config.language || "84";
        this.TableName = "D49T2000-SO";
        this.formDataName = {};
        this.attachments = [];
        this.deletedFile = [];
        this.W05F0002Inventory = null;
        this.dataInfo = {
            mode: "",
            ContractID: "",
            contractTypesChosenFromW05F0001: "",
            cboContractTypes: {},
            isPermission: null,
            Mode: 0
        };
    }

    loadApi = (cboName, params = {}, ownAction = false) => {
        this.setState({ formLoading: true });
        this.props[ownAction ? "w05f0002Actions" : "generalActions"][cboName](params, (error, data = null) => {
            this.setState({ formLoading: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.handleDataApi(cboName, data);
            }
        });
    };

    getIsQuotation = (ContractID) => {
        if (_.isEmpty(ContractID)) return;
        const params = {
            ContractID
        };
        this.props.w05f0002Actions.getIsQuotation(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (!Config.isEmpty(data?.IsQuotation, true)) {
                this.setState({ isQuotation: data.IsQuotation });
            }
        });
    };

    getIsQuotationFinish = (ContractID) => {
        if (_.isEmpty(ContractID)) return;
        const params = {
            ContractID
        };
        this.props.w05f0002Actions.getIsQuotationFinish(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (!Config.isEmpty(data?.QuotationFinish, true)) {
                this.setState({ quotationFinish: data.QuotationFinish });
            }
        });
    };

    handleDataApi = (cboName = "", data) => {
        let contractTypesData = {};
        const Language = this.Language;
        const { getDetailSupplement } = this.props;
        const { master, inventories, attachments } = data;
        const { mode, cboContractTypes } = this.dataInfo;
        switch (cboName) {
            case "loadForm": {
                if (mode !== "addFinal") {
                    contractTypesData = cboContractTypes && cboContractTypes.find(element => element.TransTypeID === master.TransTypeID);
                    if (!_.isEmpty(getDetailSupplement)) this.handleActiveInventoryColumn(getDetailSupplement, contractTypesData);
                    this.setState({ contractTypesData })
                }
                this.loadApi("getCboBankAccount", { ObjectTypeID: master.ObjectTypeID, ObjectID: master.ObjectID }, true);
                if (_.isEmpty(getDetailSupplement)) this.loadApi("getDetailSupplement", { Language }, true);
                if (master?.ExceedContract) {
                    master.ExceedContract = master.ExceedContract * 100;
                }
                this.setState({
                    getInventoryList: inventories,
                    getAttachment: attachments,
                    formData: {
                        ...master,
                        Employee: {
                            EmployeeID: master && master.EmployeeID ? master.EmployeeID : "",
                            EmployeeName: master && master.EmployeeName ? master.EmployeeName : master.EmployeeID,
                            UserPictureURL: master && master.UserPictureURL ? master.UserPictureURL : null,
                        }
                    }
                });
                if (master.ContractID) {
                    this.getIsQuotation(master.ContractID);
                    this.getIsQuotationFinish(master.ContractID);
                }
                if (!Config.isEmpty(master.CurrencyID, true)) {
                    const { CurrencyID } = master;
                    this.loadApi("getDeicimalQuantity", { CurrencyID }, true);
                }
                break;
            }
            case "loadCboCurrency": {
                if (data.length > 0) this.handleExchangeRateChange(data);
                break;
            }
            case "getDetailSupplement": {
                if (data.length > 0 && _.isEmpty(contractTypesData)) {
                    contractTypesData = cboContractTypes.find(item => item.TransTypeID === this.state.formData.TransTypeID);
                    this.handleActiveInventoryColumn(data, contractTypesData);
                }
                break;
            }
            default:
                break;
        }
    }

    handleExchangeRateChange = (cboCurrency = []) => {
        const { formData } = this.state;
        const objCurrency = cboCurrency.find(item => item.CurrencyID === formData.CurrencyID);
        if (objCurrency && objCurrency.ExchangeRate) {
            formData.ExchangeRate = objCurrency.ExchangeRate;
            this.setState({ formData })
        }
    }

    handleActiveInventoryColumn = (data = [], contractTypesData = {}) => {
        const displayName = "Display";
        const activeInventoryColumn = data.filter((keyName) => {
            if (keyName.FieldName.includes("Str") && keyName.FieldName.includes("U")) {
                keyName.FieldName = keyName.FieldName.slice(0, -1) // Bỏ chứ U phía sau DisplayStr..U  vì Data trả về là DisplayStr..
            } else if (keyName.FieldName.includes("Dat")) {
                keyName.DefaultValueU = moment(keyName.DefaultValueU, "DD/MM/YYYY").format('YYYY-MM-DD');
            }
            return contractTypesData[displayName.concat(keyName.FieldName)] === 1;
        });
        // activeInventoryColumnName = activeInventoryColumn.map(item => item.FieldName);
        this.setState({ activeInventoryColumn })
    }

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { getAttachment } = this.state;
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url || "",
                FileName: att.fileName || "",
                FileSize: att.fileSize || "",
                FileExt: att.fileExt || ""
            });
        });
        if (!_.isEmpty(getAttachment)) {
            arrAttachment = getAttachment.concat(arrAttachment);
        }
        return arrAttachment;
    };

    getInfo = async () => {
        const { location, getDetailSupplement } = this.props;
        if (location && !_.isEmpty(location.state)) {
            if (location.state.isPermission <= 0) return;
            await Config.checkRedirect(this.props.location.pathname); //Nếu có thì mới chạy check popup
            this.dataInfo.mode = location.state.mode || "add";
            this.dataInfo.cboContractTypes = location.state.cboContractTypes || "";
            this.dataInfo.ContractID = location.state.ContractID || "";
            this.dataInfo.contractTypesChosenFromW05F0001 = location.state.contractTypesChosen || "";
            this.dataInfo.isPermission = location.state.isPermission || null;
            this.dataInfo.Mode = location.state.Mode || 0;
            if (!_.isEmpty(location.state.cboContractTypes) && !_.isEmpty(location.state.contractTypesChosen) && location.state.mode === "addFinal") {
                const { contractTypesChosen, cboContractTypes } = location.state;
                const { formData } = this.state;
                const Language = this.Language;
                const contractTypesData = cboContractTypes && cboContractTypes.find(element => element.TransTypeID === contractTypesChosen);
                if (!Config.isEmpty(contractTypesData, true)) {
                    const { ContractTypeID, CurrencyID } = contractTypesData;
                    formData.CurrencyID = CurrencyID;
                    formData.ContractTypeID = ContractTypeID;
                    if (!Config.isEmpty(CurrencyID, true)) this.loadApi("getDeicimalQuantity", { CurrencyID }, true);
                    if (Config.isEmpty(getDetailSupplement, true)) {
                        this.loadApi("getDetailSupplement", { Language }, true);
                    } else { //Lấy cột động
                        this.handleActiveInventoryColumn(getDetailSupplement, contractTypesData);
                    }
                    this.setState({ contractTypesData, formData })
                }
            }
        } else {
            this.setState({ loadApiStatus: false }, () => {
                browserHistory.push(Config.getRootPath() + "W05F0001");
                return null;
            })
        }
    };

    getExceedContract = () => {
        this.props.w05f0002Actions.getExceedContract({ Language: Config.language || 84 }, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({
                formData: {
                    ...this.state.formData,
                    ExceedContract: (data?.ExceedContract ?? 0) * 100
                }
            });
        });
    };

    componentDidUpdate(preProps) {
        const { mode } = this.dataInfo;
        if (JSON.stringify(preProps.getMasterSupplement) !== JSON.stringify(this.props.getMasterSupplement) && mode === 'addFinal') {
            const _formData = {};
            this.props.getMasterSupplement.forEach(item => {
                _formData[item.FieldName] = item.DefaultValueU;
            });
            this.setState({
                formData: {
                    ...this.state.formData,
                    ..._formData
                }
            });
        }
    }

    componentDidMount = async () => {
        await this.getInfo();
        if (this.state.loadApiStatus) {
            const { mode, ContractID, contractTypesChosenFromW05F0001, Mode } = this.dataInfo;
            const { getBaseCurrency, getCboPaymentMethod, getCboStatus, getCboCurrency, getCboPorts,
                getCboDeliveryMethod, paramsMasterSup, getCboBanks, getCboDepositStatus, getMasterSupplement } = this.props;
            const Language = this.Language
            const TableName = this.TableName;
            if (mode === "edit" || mode === "copy") {
                await this.loadApi("loadForm", { ContractID }, true);
            } else { // Add
                const userID = Config.profile.UserID || "";
                const user = Config.getUser({ UserID: userID });
                let _formData = {};
                if (!_.isEmpty(getMasterSupplement)) {
                    getMasterSupplement.forEach(item => {
                        _formData[item.FieldName] = item.DefaultValueU;
                    });
                }
                this.setState({
                    formData: {
                        ...this.state.formData,
                        TransTypeID: contractTypesChosenFromW05F0001,
                        EmployeeID: user && user.ObjectID ? user.ObjectID : "",
                        EStatus: '1',
                        ..._formData
                    }
                });
                this.getExceedContract();
            }
            if (mode === "edit" && ContractID) {
                this.loadApi("loadFormView", { ContractID, Language, Mode }, true); // Get Old Data Field Name
            }
            if (_.isEmpty(getBaseCurrency)) this.loadApi("loadBaseCurrency", {}, true);
            //General Info
            if (_.isEmpty(getCboStatus)) this.loadApi("loadCboStatus", { Language }, true);
            if (_.isEmpty(getCboCurrency)) {
                this.loadApi("loadCboCurrency", {}, true);
            } else {
                this.handleExchangeRateChange(getCboCurrency);
            }
            //Payment and shipping   
            if (_.isEmpty(getCboDeliveryMethod)) this.loadApi("loadCboDeliveryMethod", {}, true);
            if (_.isEmpty(getCboPorts)) this.loadApi("loadCboPorts", {}, true);
            if (_.isEmpty(getCboPaymentMethod)) this.loadApi("loadCboPaymentMethod", {}, true);
            if (_.isEmpty(getCboBanks)) this.loadApi("loadCboBanks", {}, true);
            if (_.isEmpty(getCboDepositStatus)) this.loadApi("getCboDepositStatus", { Language }, true);
            //Other Information
            if (paramsMasterSup.TableName !== TableName) this.loadApi("getMasterSupplement", { TableName, Language }, true);
        }
    };

    componentWillUnmount = () => {
        const { location } = this.props;
        if (location && !_.isEmpty(location.state)) Config.unCheckRedirect();
        this.setState = (state, callback) => {
            return;
        };
    }

    setStateErrorText(objValue, key) {
        const stateError = key ? key : "errors";
        this.setState({
            [stateError]: { ...(this.state[stateError] ? this.state[stateError] : {}), ...objValue }
        });
        return Object.keys(objValue).length !== 0;
    }

    checkFileType = filename => {
        const extension = filename.substr(filename.lastIndexOf(".")).toLowerCase();
        const allowedExtensions = [".jpg", ".png"];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    };

    checkValid = (name, val, rules = ["isRequired"]) => {
        return { name, rules, value: val };
    };

    changeIDValueToName = (value) => {
        switch (value) {
            case "ContractTypeID": {
                value = "ContractTypeName"
                break;
            }
            case "EStatus": {
                value = "EStatusName"
                break;
            }
            case "EmployeeID": {
                value = "EmployeeName"
                break;
            }
            case "DeliveryMethodID": {
                value = "DeliveryMethodName"
                break;
            }
            case "PaymentMethodID": {
                value = "PaymentMethodName"
                break;
            }
            case "BankCode": {
                value = "BankName"
                break;
            }
            case "PortIDFrom": {
                value = "PortNameF"
                break;
            }
            case "PortIDTo": {
                value = "PortNameT"
                break;
            }
            case "ObjectID": {
                value = "ObjectNameU"
                break;
            }
            case "DepositStatus": {
                value = "DepositStatusName"
                break;
            }
            default:
                break;
        }
        return value;
    }

    getCodeLangMaster(ID, mode) {
        const { getMasterSupplement } = this.props;
        const masterSupDataField = getMasterSupplement.find(comboData => comboData.FieldName === ID);
        const listMasterValues = {
            //All
            OTotal: "ERP_Tong_gia_tri_hop_dong",
            Ctotal: "ERP_Tong_gia_tri_hop_dong_QD",
            //Tab General Information
            ContractTypeID: "ERP_Loai_hop_dong",
            ContractNo: "ERP_So_hop_dong",
            EStatus: "ERP_Trang_thai",
            CurrencyID: "ERP_Loai_tien",
            ExchangeRate: "ERP_Ty_gia",
            ContractDate: "ERP_Ngay_lap",
            EmployeeID: "ERP_Nguoi_lap",
            StartDate: "ERP_Ngay_bat_dau",
            EndDate: "ERP_Ngay_ket_thuc",
            ExceedContract: 'Dung_sai',
            //Tab Object Customer
            ObjectID: "ERP_Khach_hang",
            ObjectAddressU: "ERP_Dia_chi",
            VATNo: "ERP_Ma_so_thue",
            BankAccountNo: "ERP_So_tai_khoan",
            TelNo: "ERP_So_dien_thoai",
            FaxNo: "ERP_So_Fax",
            NotesU: "ERP_Ghi_chu",
            GObjectName: "Don_vi_uy_thac",
            //Tab Shipping Payment
            DeliveryMethodID: "ERP_Phuong_tien_van_chuyen",
            SendDate: "Thoi_gian_giao_hang_tu_ngay",
            ReturnDate: "Thoi_gian_giao_hang_den_ngay",
            PortIDFrom: "ERP_Cang_di",
            PortIDTo: "ERP_Cang_den",
            PaymentMethodID: "ERP_Phuong_thuc_thanh_toan",
            BankCode: "ERP_Ngan_hang",
            DepRate: "ERP_Ty_le_deposit",
            DepAmount: "ERP_Thanh_tien",
            DepCAmount: "ERP_Thanh_tien_QD",
            DepositStatus: "Trang_thai_deposit"
        };
        let supMasterLanguage = ""
        if (masterSupDataField) {
            supMasterLanguage = mode === "vi" ? (masterSupDataField.Caption84 ? masterSupDataField.Caption84 : "") : (masterSupDataField.Caption01 ? masterSupDataField.Caption01 : "");
        }
        return listMasterValues[ID] ? listMasterValues[ID] : supMasterLanguage;
    }

    getCodeLangInventory(ID, mode) {
        const { activeInventoryColumn } = this.state;
        const detailSupDataField = activeInventoryColumn.find(comboData => comboData.FieldName === ID);
        const listIventoryValues = {
            InventoryID: "ERP_Ma_hang",
            InventoryNameU: "ERP_Ten_hang",
            UnitID: "ERP_Don_vi_tinh",
            Quantity: "ERP_So_luong",
            UnitPrice: "ERP_Don_gia",
            OAmount: "ERP_Thanh_tien",
            CAmount: "ERP_Thanh_tien_QD",
            PackingMethodID: "ERP_Quy_cach_dong_goi",
            PackingMethodName: "ERP_Quy_cach_dong_goi",
            PackingTypeID: "ERP_Loai_bao_dong_goi",
            PackingTypeName: "ERP_Loai_bao_dong_goi",
            ParkingDeliveryDate: "ERP_Thoi_gian_giao_bao",
            MarkingID: "ERP_Nhan_mac",
            MarkingName: "ERP_Nhan_mac",
            PaymentTermID: "ERP_Dieu_khoan_thuong_mai",
            PaymentTermName: "ERP_Dieu_khoan_thuong_mai",
            Specification: "ERP_Tieu_chuan_chat_luong",
        };
        let supDetailLanguage = ""
        if (detailSupDataField) {
            supDetailLanguage = mode === "vi" ? (detailSupDataField.Caption84 ? detailSupDataField.Caption84 : "") : (detailSupDataField.Caption01 ? detailSupDataField.Caption01 : "");
        }
        return listIventoryValues[ID] ? listIventoryValues[ID] : supDetailLanguage;
    }

    onSaveHistory = async (newDataMaster, newAttachment, newInventory) => {
        const { oldDataMaster, oldDataAttachments, loadFormView, loadFormInvenView } = this.props;
        const formDataName = this.formDataName;
        const { mode, ContractID } = this.dataInfo;
        let data = []; //Chứa giá trị bị thay đổi

        /// <=======@@@@@@========> LƯU CHỈNH SỬA (MASTER, ATTACHMENT, INVENTORIES)  <=======@@@@@@========>
        if (mode === 'edit') {
            /// <=======@@@@@@========> LƯU CHỈNH SỬA MASTER  <=======@@@@@@========>
            const masterKey = Object.keys(newDataMaster);
            const excludeFields = ['Employee', 'GObjectID', 'GObjectTypeID'];
            masterKey.forEach((value) => {
                if (!excludeFields.includes(value) &&
                    (newDataMaster[value] !== (value !== 'ExceedContract' ? oldDataMaster[value] : oldDataMaster[value] / 100))) {
                    // console.log("Đây chính là Chỉnh sửa Master trong Mode EDIT");
                    const descriptionVi = this.getCodeLangMaster(value, 'vi');
                    const descriptionEn = this.getCodeLangMaster(value, 'en');
                    const changeIDValueToName = this.changeIDValueToName(value);
                    const oldValue = loadFormView[changeIDValueToName] ? loadFormView[changeIDValueToName] : "";
                    const newValue = formDataName[value] ? formDataName[value] : newDataMaster[value];
                    if (Config.isEmpty(oldValue) && Config.isEmpty(newValue) && value !== 'ExceedContract') return;
                    let el = {};
                    el.codeID = newDataMaster.ContractID;
                    el.formID = "W05F0001";
                    el.type = "text";
                    el.linkedTrans = "";
                    el.oldValue = oldValue
                    el.newValue = newValue
                    el.description84 = Config.lang(descriptionVi, "vi");
                    el.description01 = Config.lang(descriptionEn, "en");
                    el.action = 1;
                    data.push(el);
                }
            });

            /// <=======@@@@@@========> LƯU INVENTORIES - MODE: "EDIT" <=======@@@@@@========>
            if (!_.isEmpty(newInventory) && !_.isEmpty(loadFormInvenView)) {
                let newDataInventoryValuesID = [];
                let inventoriesFieldName = [];
                inventoriesFieldName = loadFormInvenView.map(jaja => Object.keys(jaja))[0];
                newDataInventoryValuesID = newInventory.map(item => item.SalesItemID);

                /// <=====================> Lưu Xóa Inventories - MODE: "EDIT" <=====================>
                loadFormInvenView.forEach((dataCu) => {
                    if ((newDataInventoryValuesID.indexOf(dataCu.SalesItemID) === -1)) {
                        // console.log("Đây chính là Xóa Hàng Hóa trong Mode EDIT");
                        let el = {};
                        el.codeID = dataCu.ContractID;
                        el.formID = "W05F0001";
                        el.linkedTrans = "";
                        el.oldValue = dataCu.InventoryID + "-" + dataCu.InventoryNameU;
                        el.newValue = "";
                        el.description84 = Config.lang("ERP_Hang_hoa", "vi");
                        el.description01 = Config.lang("ERP_Hang_hoa", "en");
                        el.action = 3;
                        data.push(el);
                    }
                })

                /// <=====================> Lưu Chỉnh sửa Inventories - MODE: "EDIT" <=====================>
                newInventory.forEach((dataMoi) => {
                    loadFormInvenView.forEach(dataCu => {
                        inventoriesFieldName.forEach(fieldName => {
                            // const filterCboInvenID = ["PackingMethodID", "PackingTypeID", "MarkingID", "PaymentTermID"];
                            if (!_.isUndefined(dataMoi[fieldName]) && dataMoi.SalesItemID === dataCu.SalesItemID && dataMoi[fieldName] !== dataCu[fieldName]) {
                                // console.log("Đây chính là Chỉnh sửa hàng hóa trong Mode EDIT");
                                const descriptionVi = this.getCodeLangInventory(fieldName, 'vi');
                                const descriptionEn = this.getCodeLangInventory(fieldName, 'en');
                                let el = {};
                                el.codeID = newDataMaster.ContractID;
                                el.formID = "W05F0001";
                                el.type = "text";
                                el.linkedTrans = "";
                                el.oldValue = dataCu[fieldName];
                                el.newValue = dataMoi[fieldName];
                                el.description84 = Config.lang(descriptionVi, "vi") + " (" + Config.lang("ERP_Hang_hoa", "vi") + ": " + dataCu.InventoryID + "-" + dataCu.InventoryNameU + ")";
                                el.description01 = Config.lang(descriptionEn, "en") + " (" + Config.lang("ERP_Hang_hoa", "en") + ": " + dataCu.InventoryID + "-" + dataCu.InventoryNameU + ")";
                                el.action = 1;
                                data.push(el);
                            }
                        });
                    });
                });
                if (!_.isEmpty(newInventory)) {
                    /// <=====================> Lưu Thêm Mới INVENTORIES - MODE: "EDIT" <=====================>
                    let dataNewInventorySalesItemID = [];
                    dataNewInventorySalesItemID = loadFormInvenView.map(item => item.SalesItemID);
                    newInventory.forEach((dataMoi) => {
                        if (dataNewInventorySalesItemID.indexOf(dataMoi.SalesItemID) === -1) {
                            // console.log("Đây chính là Add new hàng hóa trong Mode EDIT");
                            let el = {};
                            el.codeID = newDataMaster.ContractID;
                            el.formID = "W05F0001";
                            el.linkedTrans = "";
                            el.oldValue = "";
                            el.newValue = dataMoi.InventoryID + "-" + dataMoi.InventoryNameU
                            el.description84 = Config.lang("ERP_Hang_hoa", "vi");
                            el.description01 = Config.lang("ERP_Hang_hoa", "en");
                            el.action = 2;
                            data.push(el);
                        }
                    });
                }
            }
            /// <=======@@@@@@========> LƯU INVENTORIES - MODE: "EDIT" <=======@@@@@@========>
            if (!_.isEmpty(oldDataAttachments)) {
                /// <=====================> Lưu Xóa ATTACHMENT - MODE: "EDIT" <=====================>
                let newDataInventoryValuesID = [];
                newDataInventoryValuesID = newAttachment.map(item => item.URL);
                oldDataAttachments.forEach((dataCu) => {
                    if ((newDataInventoryValuesID.indexOf(dataCu.URL) === -1)) {
                        // console.log("Đây chính là Xóa Tệp đính kèm trong Mode EDIT");
                        let el = {};
                        el.codeID = newDataMaster.ContractID;
                        el.formID = "W05F0001";
                        el.linkedTrans = "";
                        el.oldValue = dataCu.FileName;
                        el.newValue = "";
                        el.description84 = Config.lang("ERP_Dinh_kem", "vi");
                        el.description01 = Config.lang("ERP_Dinh_kem", "en");
                        el.action = 3;
                        data.push(el);
                    }
                })
            }
            if (!_.isEmpty(newAttachment)) {
                /// <=====================> Lưu Thêm Mới ATTACHMENT - MODE: "EDIT" <=====================>
                let dataOldAttachmentURL = [];
                dataOldAttachmentURL = oldDataAttachments.map(item => item.URL);
                newAttachment.forEach((dataMoi) => {
                    if (dataOldAttachmentURL.indexOf(dataMoi.URL) === -1) {
                        // console.log("Đây chính là Add new Tệp đính kèm trong Mode EDIT");
                        let el = {};
                        el.codeID = newDataMaster.ContractID;
                        el.formID = "W05F0001";
                        el.linkedTrans = "";
                        el.oldValue = "";
                        el.newValue = dataMoi.FileName;
                        el.description84 = Config.lang("ERP_Dinh_kem", "vi");
                        el.description01 = Config.lang("ERP_Dinh_kem", "en");
                        el.action = 2;
                        data.push(el);
                    }
                });
            }
        }
        /// <=======@@@@@@========> LƯU CHỈNH SỬA (MASTER, ATTACHMENT, INVENTORIES)  <=======@@@@@@========>


        /// <=======@@@@@@========> LƯU THÊM MỚI (MASTER, ATTACHMENT, INVENTORIES)  <=======@@@@@@========>
        else { // MODE === addFinal or MODE === copy
            /// <=====================> Lưu Thêm Mới MASTER - MODE: "ADD" <=====================>
            let el = {};
            el.codeID = ContractID;
            el.formID = 'W05F0001';
            el.linkedTrans = "";
            el.oldValue = "";
            el.newValue = newDataMaster.ContractNo;
            el.description84 = Config.lang("ERP_Hop_dong", "vi");
            el.description01 = Config.lang("ERP_Hop_dong", "en");
            el.action = 0;
            data.push(el);

            if (!_.isEmpty(newInventory)) {/// <=====================> Lưu Thêm Mới INVENTORIES - MODE: "ADD" <=====================>
                newInventory.forEach((dataMoi) => {
                    // console.log("Đây chính là Add new hàng hóa trong Mode ADD");
                    let el = {};
                    el.codeID = ContractID;
                    el.formID = "W05F0001";
                    el.linkedTrans = "";
                    el.oldValue = "";
                    el.newValue = dataMoi.InventoryID + "-" + dataMoi.InventoryNameU;
                    el.description84 = Config.lang("ERP_Hang_hoa", "vi");
                    el.description01 = Config.lang("ERP_Hang_hoa", "en");
                    el.action = 2;
                    data.push(el);
                });
            }
            if (!_.isEmpty(newAttachment)) {/// <=====================> Lưu Thêm Mới ATTACHMENT - MODE: "ADD" <=====================>
                newAttachment.forEach((dataMoi) => {
                    // console.log("Đây chính là Add new tệp đính kèm trong Mode ADD");
                    let el = {};
                    el.codeID = ContractID;
                    el.formID = "W05F0001";
                    el.linkedTrans = "";
                    el.oldValue = "";
                    el.newValue = newAttachment.FileName;
                    el.description84 = Config.lang("ERP_Dinh_kem", "vi");
                    el.description01 = Config.lang("ERP_Dinh_kem", "en");
                    el.action = 2;
                    data.push(el);
                });
            }
        }
        //Format Date Time Value 
        const valueFieldName = ["newValue", "oldValue"];
        valueFieldName.forEach(fieldName => {
            data.forEach(obj => {
                if (Config.isValidDateTime(obj[fieldName])) {
                    obj[fieldName] = moment.utc(obj[fieldName], "YYYY-MM-DD").format("DD/MM/YYYY");
                }
            })
        })
        if (data.length > 0) {
            const param = {
                attributes: JSON.stringify(data),
            };
            await this.props.w05f0002Actions.saveHistoryData(param, (error, data) => {
                this.setState({ formLoading: false, isSave: false });
                if (error) {
                    let message = error.message || Config.lang("ERP_Luu_lich_su_khong_thanh_cong");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    this.saveSuccessAction();
                }
            });
        } else {
            this.saveSuccessAction();
        }
    };

    saveSuccessAction = async () => {
        await Config.unCheckRedirect();
        this.setState({ disableSaveBtn: true, formLoading: false, isUpdated: false }, () => {
            Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
            //Đang bị lỗi dính thông báo chuyển trang nên tạm ẩn 
            setTimeout(() => {
                const { isPermission, ContractID, contractTypesChosenFromW05F0001: contractTypesChosen = "", cboContractTypes } = this.dataInfo;
                browserHistory.push({
                    pathname: Config.getRootPath() + "W05F0003",
                    state: {
                        mode: "view",
                        ContractID,
                        cboContractTypes,
                        contractTypesChosen,
                        isPermission
                    }
                })
            }, 0);
        });
    }

    getDataSave = () => {
        const { formData } = this.state;
        const { mode } = this.dataInfo
        let _formData = { ...formData };
        if (mode === "addFinal") {
            const { getMasterSupplement } = this.props;
            getMasterSupplement.forEach(item => {
                const formDataKeyName = Object.keys(_formData);
                if (item.DefaultUse === 1 && !_.isEmpty(item.DefaultValueU) && formDataKeyName.indexOf(item.FieldName) < 0) {
                    const { FieldName, DefaultValueU, DataType } = item;
                    const checkValue = DataType === "datetime" ? moment(DefaultValueU).format('YYYY-MM-DD') : DefaultValueU;
                    _formData[FieldName] = checkValue;
                }
            });
        }
        Object.keys(_formData).forEach(dataMaster => {
            if (Config.isValidDateTime(_formData[dataMaster])) {
                _formData[dataMaster] = moment(_formData[dataMaster]).format("YYYY-MM-DD")
            }
        });
        if (_formData.hasOwnProperty("Employee")) delete _formData.Employee;
        if (_formData.hasOwnProperty("EmployeeName")) delete _formData.EmployeeName;
        _formData.ExceedContract = _formData.ExceedContract / 100;
        return _formData;
    };

    onSave = async () => {
        // const getInventoryList = this.W05F0002Inventory.instance.option("dataSource") || [];
        let checkReqiredData = false;
        const { getInventoryList } = this.state;
        const Language = this.Language;
        let dataSourceInventory = getInventoryList;
        if (this.W05F0002Inventory) {
            await this.W05F0002Inventory.instance.saveEditData();
            dataSourceInventory = this.W05F0002Inventory.instance.option("dataSource");
            await new Promise(resolve => setTimeout(resolve, 500));
        }
        const { location, oldDataInventories } = this.props;
        const { formData, isEmptyInventory, tabKey } = this.state;
        const mode = _.get(location, "state.mode", "edit");
        const apiSave = mode === "addFinal" || mode === "copy" ? "saveAddNew" : "saveEdit";
        const {
            TransTypeID,
            ContractID,
            EmployeeID,
            ContractNo,
            ContractTypeID,
            EStatus,
            CurrencyID,
            ContractDate,
            StartDate,
            EndDate,
            ExchangeRate,
            ObjectID
        } = formData;

        const fTransTypeID = this.checkValid("TransTypeID", TransTypeID);
        const fEmployeeID = this.checkValid("EmployeeID", EmployeeID);
        const fContractNo = this.checkValid("ContractNo", ContractNo);
        const fContractTypeID = this.checkValid("ContractTypeID", ContractTypeID);
        const fEStatus = this.checkValid("EStatus", EStatus);
        const fCurrencyID = this.checkValid("CurrencyID", CurrencyID);
        const fContractDate = this.checkValid("ContractDate", ContractDate);
        const fStartDate = this.checkValid("StartDate", StartDate);
        const fEndDate = this.checkValid("EndDate", EndDate);
        const zero = 0;
        const fExchangeRate = this.checkValid("ExchangeRate", ExchangeRate ? ExchangeRate : zero.toString());
        const fCObjectID = this.checkValid("ObjectID", ObjectID);

        const validateForm = MForm.formValidation([
            fTransTypeID,
            fEmployeeID,
            fContractNo,
            fContractTypeID,
            fEStatus,
            fCurrencyID,
            fContractDate,
            fStartDate,
            fEndDate,
            fExchangeRate,
            fCObjectID
        ]);
        let dataMasterFormat = this.getDataSave();
        await dataSourceInventory.forEach(item => {
            const { Quantity, UnitPrice } = item;
            if (_.isNull(Quantity) || _.isUndefined(UnitPrice) || _.toNumber(Quantity) === 0 || _.toNumber(UnitPrice) === 0) {
                checkReqiredData = true;
            }
        });
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            if (tabKey !== 0) this.setState({ tabKey: 0 });
            return false;
        } else if (isEmptyInventory || _.isEmpty(dataSourceInventory)) {
            if (tabKey !== 1) this.setState({ tabKey: 1 });
            Config.popup.show("INFO", Config.lang("ERP_Vui_long_chon_hang_hoa"));
            return false;

        } else if (checkReqiredData) {
            if (tabKey !== 1) this.setState({ tabKey: 1 });
            Config.popup.show("INFO", Config.lang("ERP_Vui_long_nhap_so_luong_va_gia_hang_hoa"));
            return false;
        } else {
            if (this.W05F0002Inventory) {
                await this.W05F0002Inventory.instance.saveEditData();
                dataSourceInventory = this.W05F0002Inventory.instance.option("dataSource");
            }
            dataSourceInventory.forEach(data => {
                Object.keys(data).forEach(key => {
                    if (data[key] && Config.isValidDateTime(data[key])) {
                        data[key] = moment(data[key]).format("YYYY-MM-DD")
                    }
                });
            });
            const arrAttachments = this._getAttachments();
            const arrayInvenIDNew = dataSourceInventory.map(inven => inven.SalesItemID);
            const arrayInvenIDOld = oldDataInventories.map(inven => inven.SalesItemID);
            let removed = oldDataInventories.filter(inven => arrayInvenIDNew.indexOf(inven.SalesItemID) < 0);
            const added = dataSourceInventory.filter(inven => arrayInvenIDOld.indexOf(inven.SalesItemID) < 0);
            const edited = dataSourceInventory.filter(inven => arrayInvenIDOld.indexOf(inven.SalesItemID) > -1);
            let params = {};
            if (ContractID && mode === "edit") {
                params = {
                    ContractID: ContractID,
                    Language,
                    master: JSON.stringify(dataMasterFormat),
                    detail: JSON.stringify({
                        OTotal: dataMasterFormat.OTotal,
                        CTotal: dataMasterFormat.Ctotal,
                        inventories: {
                            removed: removed,
                            added: added,
                            edited: edited
                        }
                    }),
                    attachments: JSON.stringify(arrAttachments)
                };
            } else { // add or copy run api insert
                params = {
                    Language,
                    master: JSON.stringify(dataMasterFormat),
                    detail: JSON.stringify({
                        OTotal: dataMasterFormat.OTotal,
                        CTotal: dataMasterFormat.Ctotal,
                        inventories: dataSourceInventory
                    }),
                    attachments: JSON.stringify(arrAttachments)
                };
            }
            this.setState({ formLoading: true, isSave: true });
            this.props.w05f0002Actions[apiSave](params, (error, data) => {
                const errorCode = ["W05F0000E203"];
                if (error) {
                    let defaultMessage = Config.lang("ERP_Loi_chua_xac_dinh");
                    if (apiSave === "saveAddNew" && error) {
                        switch (error.code) {
                            case "W05F0000E203":
                                defaultMessage = Config.lang("ERP_So_hop_dong_da_ton_tai");
                                break;
                            case "null":
                                defaultMessage = Config.lang("ERP_Khong_ton_tai");
                                break;
                            default:
                                defaultMessage = Config.lang("ERP_Luu_khong_thanh_cong");
                                break;
                        }
                    }
                    Config.popup.show("INFO", errorCode.includes(error.code) ? defaultMessage : error.message);
                    this.setState({ formLoading: false, isSave: false });
                    return false;
                }
                if (data) {
                    if (data.ContractID) {
                        this.dataInfo.ContractID = data.ContractID;
                    }
                    this.onSaveHistory(dataMasterFormat, arrAttachments, dataSourceInventory);
                }
            });
        }
    };

    calculateDepCAmount = (ExchangeRate) => {
        const { getInventoryList, formData } = this.state;
        getInventoryList.forEach(item => {
            const { UnitPrice, Quantity } = item;
            if (_.isUndefined(Quantity) && _.isUndefined(UnitPrice)) return;
            item.CAmount = Quantity * UnitPrice * ExchangeRate;
        })
        formData.Ctotal = formData.OTotal * ExchangeRate;
    }

    onChange = (e, nameField) => {
        const value = _.isObject(e) ? _.get(e, "value", _.get(e, "target.value", e ? e : "")) : e;
        const { formData } = this.state;
        if (nameField === "ExchangeRate" && formData.ExchangeRate !== value) {
            this.calculateDepCAmount(value);
        }
        if (nameField === 'DepAmount') {
            formData.DepCAmount = value * formData.ExchangeRate;
        }
        this.setState({
            isUpdated: true,
            formData: {
                ...formData,
                [nameField]: !_.isNull(value) ? value : ""
            }
        });
    };

    onChangeFormData = (value, nameField) => {
        const { formData } = this.state;
        formData[nameField] = value;
        if (!this.state.isUpdated) this.setState({ isUpdated: true })
    };

    onLoading = (yesNo) => {
        this.setState({ formLoading: yesNo });
    }

    onChangeCbo = (valueName, nameField) => {
        if (_.isNull(valueName)) return;
        if (!this.state.isUpdated) this.setState({ isUpdated: true });
        this.formDataName = {
            ...this.formDataName,
            [nameField]: valueName
        }
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onChangeAttachments = e => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map(d => d.URL);
            this.setState(prevState => ({
                getAttachment: prevState.getAttachment.filter(att => {
                    return _arrRemove.indexOf(att.URL) < 0;
                })
            }));
        }
        if (!this.state.isUpdated) this.setState({ isUpdated: true })
    };

    onUploading = value => {
        this.setState({ uploading: value });
    };

    onBack = () => {
        if (this.state.isUpdated) {
            Config.popup.show("YES_NO", Config.lang("ERP_Du_lieu_chua_duoc_luu_ban_co_muon_tiep_tuc_khong"), () => {
                browserHistory.goBack();
            });
        } else {
            browserHistory.goBack();
        }
    }

    handleTabChange = (e, key) => {
        if (this.W05F0002Inventory) {
            this.W05F0002Inventory.instance.saveEditData();
        }
        this.setState({ tabKey: key });
    };

    onCloseModal = () => {
        const { formData } = this.state;
        if (!formData.TransTypeID) return;
        this.setState({
            modalContractTypes: false
        });
    };

    onChangeTranType = (e) => {
        this.setState({ contractTypesChosen: e.value });
    }

    loadContractNo = (ContractTypeID) => {
        if (_.isEmpty(ContractTypeID) || _.isUndefined(ContractTypeID)) return;
        const { formData } = this.state;
        let ContractNo = "";
        if (ContractTypeID) {
            const params = { ContractTypeID };
            this.setState({ formLoading: true });
            this.props.w05f0002Actions.getContractNo(params, (errors, data) => {
                if (errors) {
                    this.setState({ formLoading: false });
                    let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if (data && data.ContractNo) {
                    ContractNo = data.ContractNo;
                    formData.ContractNo = ContractNo;
                    this.setState({ formData, formLoading: false })
                }
            });
        }
    };

    onSaveTranType = () => {
        const { getCboCurrency, getDetailSupplement, getCboContractType } = this.props;
        const { formData, contractTypesChosen } = this.state;
        const { cboContractTypes, mode } = this.dataInfo;
        const contractTypesData = cboContractTypes.find(item => item.TransTypeID === contractTypesChosen);
        if (mode === "addFinal" && !_.isEmpty(getCboContractType)) { // Lấy CurrencyID và ContractTypeID default từ contractTypes đã chọn
            const { ContractTypeID, CurrencyID } = contractTypesData;
            const objCurrency = getCboCurrency.find(item => item.CurrencyID === CurrencyID);
            formData.CurrencyID = CurrencyID;
            formData.ContractTypeID = ContractTypeID;
            formData.ExchangeRate = !_.isUndefined(objCurrency) ? objCurrency.ExchangeRate : 0;
            if (!Config.isEmpty(CurrencyID, true)) this.loadApi("getDeicimalQuantity", { CurrencyID }, true);
            const loadContractNo = (getCboContractType.find(item => item.ContractTypeID === formData.ContractTypeID)).Auto === 1;
            this.setState(prevState => {
                return ({
                    isUpdated: true,
                    modalContractTypes: false,
                    contractTypesChosen: "",
                    contractTypesData,
                    formData: {
                        ...formData,
                        ContractNo: loadContractNo ? formData.ContractNo : "",
                        TransTypeID: prevState.contractTypesChosen
                    }
                })
            }, () => {
                if (loadContractNo) this.loadContractNo(contractTypesData.ContractTypeID); // Auto = 1 thì load ConTractNo
                this.handleActiveInventoryColumn(getDetailSupplement, contractTypesData);
            })
        }
    }

    renderTranType = (cboContractTypes, contractTypesChosen, mode) => {
        const tranTypeChosen = (cboContractTypes.find(item => item.TransTypeID === contractTypesChosen));
        if (_.isEmpty(tranTypeChosen)) return;
        const line = !_.isEmpty(tranTypeChosen.TransTypeName) ? "-" : "";
        return (
            <ButtonGeneral
                typeButton={'custom_edit'}
                className={"bg_none"}
                variant={"custom"}
                size={"large"}
                reverseIcon={true}
                name={`${tranTypeChosen.TransTypeID} ${line} ${tranTypeChosen.TransTypeName}`}
                disabled={mode !== "addFinal"}
                style={{ textTransform: "uppercase", width: window.innerWidth < 576 ? '-webkit-fill-available' : 'auto' }}
                onClick={() => this.setState({ modalContractTypesYesNo: true })}
            />
        )
    }

    render() {
        const { formData, errors, expandedFirst, expandedSecond, expandedThird, expandedFour, modalContractTypesYesNo, getAttachment, contractTypesData, isQuotation,
            formLoading, tabKey, modalContractTypes, error, contractTypesChosen, getInventoryList, disableSaveBtn, activeInventoryColumn, uploading, isSave, quotationFinish } = this.state;
        const { classes, getDecimalQuantity } = this.props;
        const { cboContractTypes, mode, isPermission } = this.dataInfo;
        const { TransTypeID } = formData;
        if (isPermission <= 0) return null;

        return (
            <>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={{ my: 'center', of: "#content" }}
                    visible={isSave}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <Modal open={modalContractTypes}
                    maxWidth={"sm"}
                    fullWidth={true}
                    position={'center'}>
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                            <div className={"display_row align-center"}>
                                <Typography variant="h6" className={"mgr10 text-uppercase"}>{Config.lang("ERP_Nghiep_vu")}</Typography>
                            </div>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={this.onCloseModal} >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </Modal.Title>
                    <Modal.Content>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Combo
                                    label={Config.lang("ERP_Loai_nghiep_vu")}
                                    shrink={true}
                                    required={true}
                                    margin={"normal"}
                                    valueExpr={"TransTypeID"}
                                    stylingMode={"underlined"}
                                    dataSource={cboContractTypes}
                                    value={TransTypeID}
                                    error={error && error["TransTypeID"]}
                                    disabled={formLoading}
                                    displayExpr={(e) => {
                                        if (!e) return null;
                                        const line = !_.isEmpty(e.TransTypeName) ? "-" : "";
                                        return `${e.TransTypeID} ${line} ${e.TransTypeName}`;
                                    }}
                                    itemRender={(e) => {
                                        if (!e) return null;
                                        const line = !_.isEmpty(e.TransTypeName) ? "-" : "";
                                        return `${e.TransTypeID} ${line} ${e.TransTypeName}`;
                                    }}
                                    onValueChanged={(e) => this.onChangeTranType(e)}
                                />
                            </Col>
                        </Row>
                        <ButtonGeneral name={Config.lang("ERP_Dong_y")}
                            size={"large"}
                            color={"primary"}
                            variant={"contained"}
                            style={{ textTransform: 'uppercase', float: 'right', marginTop: 12 }}
                            disabled={formLoading || !contractTypesChosen}
                            onClick={this.onSaveTranType} />
                    </Modal.Content>
                </Modal>

                <Modal open={modalContractTypesYesNo}
                    maxWidth={"sm"}
                    fullWidth={true}
                    position={"center"}>
                    <Modal.Content style={{ textAlign: "center" }}>
                        <div className={classes.yesNoContent}>{Config.lang("ERP_Nghiep_vu_da_co_du_lieu")}</div>
                        <Row>
                            <ButtonGeneral name={Config.lang("ERP_Huy")}
                                size={"large"}
                                color={"primary"}
                                variant={"outlined"}
                                disabled={formLoading}
                                style={{ textTransform: 'uppercase', marginRight: '24px' }}
                                onClick={() => this.setState({ modalContractTypesYesNo: false })} />
                            <ButtonGeneral name={Config.lang("ERP_Dong_y")}
                                size={"large"}
                                color={"primary"}
                                variant={"contained"}
                                disabled={formLoading}
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => this.setState({ modalContractTypes: true, modalContractTypesYesNo: false })} />
                        </Row>
                    </Modal.Content>
                </Modal>

                <ActionToolbar
                    allwaysTop
                    title={Config.lang("ERP_Chi_tiet_hop_dong_ban")}
                    onBack={this.onBack}>
                    <div style={{ width: '100%', display: window.innerWidth < 576 ? '' : 'inline-flex' }}>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <ButtonGeneral
                                name={Config.lang("ERP_Luu")}
                                size={"large"}
                                typeButton={"save"}
                                style={{ textTransform: "uppercase" }}
                                disabled={formLoading || disableSaveBtn}
                                onClick={this.onSave}
                            />
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} {...(window.innerWidth >= 768 ? { style: { textAlign: 'right', paddingRight: 0 } } : {})} >
                            {TransTypeID && cboContractTypes && this.renderTranType(cboContractTypes, TransTypeID, mode)}
                        </Col>
                    </div>
                </ActionToolbar>
                <Tabs
                    value={tabKey}
                    component={"div"}
                    scrollButtons={"off"}
                    textColor={"primary"}
                    variant={"scrollable"}
                    indicatorColor={"primary"}
                    aria-label={"full width tabs"}
                    // style={{ fontSize: '16px', padding: '0 16px 0 12px' }}
                    style={{ fontSize: '16px' }}
                    onChange={this.handleTabChange}
                >
                    <Tab href={""} label={Config.lang("ERP_Thong_tin_chung")} />
                    <Tab href={""} label={Config.lang("ERP_Hang_hoa")} />
                </Tabs>
                <TabContent
                    style={{ paddingBottom: 10, backgroundColor: '#fff' }}
                    activeKey={tabKey}
                    disableSwipe={true}
                    lazyLoading={false}
                    onChangeIndex={this.handleChangeIndex}>
                    <TabPanel index={0}>
                        {((mode !== "addFinal" ? !_.isEmpty(formData.ContractID) : true) && (mode !== "addFinal" ? !_.isEmpty(getDecimalQuantity) : true)) &&
                            <React.Fragment>
                                <div style={{ padding: "0px 3px 0px 3px", width: "100%" }}>
                                    <ExpansionPanel
                                        expanded={expandedFirst}
                                        className={classes.collapseBoard}
                                        onChange={() => this.setState({ expandedFirst: !expandedFirst })}
                                    >
                                        <ExpansionPanelSummary className={classes.ExpansionPanelSummary} >
                                            <i className={"fa display_row align-center " + (expandedFirst ? "fa-angle-down " : "fa-angle-right ") + classes.iconArrowCollapse} />
                                            <Typography className={classes.heading}>{Config.lang("ERP_Thong_tin_chung")}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                            <W05F0002GeneralInfo
                                                mode={mode}
                                                errorForm={errors}
                                                formData={formData}
                                                loading={formLoading}
                                                isQuotation={isQuotation}
                                                quotationFinish={quotationFinish}
                                                onLoading={(yesNo) => this.onLoading(yesNo)}
                                                onChange={(e, name) => this.onChange(e, name)}
                                                onChangeCbo={(valueName, fieldName) => this.onChangeCbo(valueName, fieldName)}
                                            />

                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel
                                        expanded={expandedSecond}
                                        className={classes.collapseBoard}
                                        onChange={() => this.setState({ expandedSecond: !expandedSecond })}
                                    >
                                        <ExpansionPanelSummary
                                            className={classes.ExpansionPanelSummary}
                                        >
                                            <i className={"fa display_row align-center " + (expandedSecond ? "fa-angle-down " : "fa-angle-right ") + classes.iconArrowCollapse} />
                                            <Typography className={classes.heading}>
                                                {Config.lang("ERP_Khach_hang")}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                            <W05F0002ObjectCustomer
                                                mode={mode}
                                                errorForm={errors}
                                                formData={formData}
                                                loading={formLoading}
                                                contractTypesData={contractTypesData}
                                                onSave={this.onSave}
                                                onChange={(e, name) => this.onChange(e, name)}
                                                onChangeCbo={(valueName, fieldName) => this.onChangeCbo(valueName, fieldName)}
                                                loadApi={this.loadApi}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel
                                        expanded={expandedThird}
                                        className={classes.collapseBoard}
                                        onChange={() => this.setState({ expandedThird: !expandedThird })}
                                    >
                                        <ExpansionPanelSummary
                                            className={classes.ExpansionPanelSummary}
                                        >
                                            <i className={"fa display_row align-center " + (expandedThird ? "fa-angle-down " : "fa-angle-right ") + classes.iconArrowCollapse} />
                                            <Typography className={classes.heading}>
                                                {Config.lang("ERP_Van_chuyen_thanh_toan")}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                            <W05F0002ShippingPayment
                                                mode={mode}
                                                errorForm={errors}
                                                formData={formData}
                                                loading={formLoading}
                                                onSave={this.onSave}
                                                onChange={(e, name) => this.onChange(e, name)}
                                                onChangeCbo={(valueName, fieldName) => this.onChangeCbo(valueName, fieldName)}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel
                                        expanded={expandedFour}
                                        className={classes.collapseBoard}
                                        onChange={() => this.setState({ expandedFour: !expandedFour })}
                                    >
                                        <ExpansionPanelSummary
                                            className={classes.ExpansionPanelSummary}
                                        >
                                            <i className={"fa display_row align-center " + (expandedFour ? "fa-angle-down " : "fa-angle-right ") + classes.iconArrowCollapse} />
                                            <Typography className={classes.heading}>
                                                {Config.lang("ERP_Thong_tin_khac")}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                            <W05F0002OtherInfo
                                                mode={mode}
                                                formData={formData}
                                                loading={formLoading}
                                                onChange={(e, name) => this.onChange(e, name)}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                    <div style={{ width: "100%", marginTop: '20px' }}>
                                        <Row>
                                            <Col xs={12}>
                                                <Attachments
                                                    showButton={true}
                                                    files={getAttachment}
                                                    uploading={formLoading}
                                                    disabled={uploading}
                                                    loading={uploading || formLoading}
                                                    ref={ref => (this.attRef = ref)}
                                                    onUploading={this.onUploading}
                                                    onChanged={this.onChangeAttachments}
                                                />
                                            </Col>
                                        </Row>
                                        <ButtonGeneral
                                            name={Config.lang("ERP_Dinh_kem")}
                                            typeButton={"attachment"}
                                            variant={"outlined"}
                                            size={"large"}
                                            disabled={formLoading}
                                            style={{ textTransform: "uppercase", marginTop: 7 }}
                                            onClick={this.onAttachment}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </TabPanel>
                    <TabPanel index={1} lazyLoading={true}>
                        <React.Fragment>
                            <W05F0002Inventory
                                mode={mode}
                                formData={formData}
                                formDataList={getInventoryList}
                                cboContractTypes={cboContractTypes}
                                contractTypesData={contractTypesData}
                                activeInventoryColumn={activeInventoryColumn}
                                isUpdated={() => {
                                    if (!this.state.isUpdated) this.setState({ isUpdated: true })
                                }}
                                isUpdatedStatus={this.state.isUpdated}
                                gridRef={ref => this.W05F0002Inventory = ref}
                                onChange={(e, name) => this.onChange(e, name)}
                                onChangeFormData={(e, name) => this.onChangeFormData(e, name)}
                                onChangeInventories={(getInventoryList) => {
                                    this.setState({ getInventoryList, isEmptyInventory: false })
                                    if (getInventoryList.length < 1) {
                                        this.setState({ isEmptyInventory: true })
                                    }
                                }}
                            />
                        </React.Fragment>
                    </TabPanel>
                </TabContent>
            </>
        );
    }
}
export default compose(
    connect(
        (state) => ({
            oldDataMaster: state.W05F0002.oldDataMaster,
            oldDataAttachments: state.W05F0002.oldDataAttachments,
            oldDataInventories: state.W05F0002.oldDataInventories,
            getMasterSupplement: state.W05F0002.getMasterSupplement,
            getDetailSupplement: state.W05F0002.getDetailSupplement,
            getDecimalQuantity: state.W05F0002.getDecimalQuantity,
            loadFormView: state.W05F0002.loadFormView,
            loadFormInvenView: state.W05F0002.loadFormInvenView,
            getCboPaymentMethod: state.W05F0002.getCboPaymentMethod,
            getCboStatus: state.W05F0002.getCboStatus,
            getCboCurrency: state.W05F0002.getCboCurrency,
            getCboPorts: state.W05F0002.getCboPorts,
            getCboDeliveryMethod: state.W05F0002.getCboDeliveryMethod,
            paramsMasterSup: state.W05F0002.paramsMasterSup,
            getCboBanks: state.W05F0002.getCboBanks,
            getBaseCurrency: state.W05F0002.getBaseCurrency,
            getCboContractType: state.W05F0002.getCboContractType,
            getCboDepositStatus: state.W05F0002.getCboDepositStatus
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w05f0002Actions: bindActionCreators(W05F0002Actions, dispatch)
        })
    ),
    withStyles(styles, { withTheme: true })
)(W05F0002);
