import { FormLabel as Label } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { TextBox } from 'devextreme-react';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0001Actions from '../../../../redux/W0X/W05F0001/W05F0001_actions';
import { Combo, MForm, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import NumberFormatCustom from '../../../common/form-material/numberFormatField';
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    contentCode: {
        width: '100%',
        marginBottom: 15,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divHeaderTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '0.85rem',
        textTransform: 'uppercase',
        color: '#8F9BB3',
    },
    divHeaderItem: {
        marginLeft: 8,
        fontSize: 16,
        fontWeight: 600,
        fontStyle: 'normal',
    },
    cellPadding: {
        '& .dx-datagrid-content': {
            '& td': {
                paddingTop: '8px !important',
                paddingBottom: '8px !important',
            }
        }
    },
    checkBoxGrid: {
        padding: 0,
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    inputField: {
        transform: 'translateY(-5px)',
        backgroundColor: 'transparent !important',
        marginTop: '0 !important',
    },
    textBoxField: {
        backgroundColor: 'transparent !important',
        '& input': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        }
    }
};

class W05F0001UpdateInfoCancel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            btnSaveStatus: false,
            totalDetailInfoCancel: 0,
            errorForm: {},
            dataForm: {
                master: {
                    ExchangeValue: 0,
                    ProjectID: "",
                    ProjectName: "",
                    GuaranteeClause01U: "",
                    GuaranteeDate: moment().format("YYYY-MM-DD"), // Default ngày
                },
                detail: []
            },
            detailInfoCancel: [],
        }
        this.dataGrid = null;
        this.Language = Config.language || "84";
        this.oldData = { master: {}, detail: [] };
    }

    componentDidMount = async () => {
        const { dataCboProject } = this.props;
        this.getCancelInformation();
        if (_.isEmpty(dataCboProject)) this.getCboProject();
    };

    getCancelInformation = () => {
        const { currentRowSelected } = this.props;
        const params = {
            Language: this.Language,
            ContractID: currentRowSelected.ContractID,
            // ContractID: "49CT0Y700000067"
        };
        this.setState({ loading: true });
        this.props.w05f0001Actions.getDataCancelInformation(params, (error, data) => {
            if (error) {
                this.setState({ loading: false });
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                let dataMaster = data.master;
                if (!_.isEmpty(data?.master)) {
                    const { dataObj } = this.formatDateTimeObj({ ...data.master });
                    dataMaster = dataObj;
                    this.oldData.master = { ...dataObj };
                }
                if (!_.isEmpty(data?.detail)) {
                    this.oldData.detail = data.detail.map(obj => ({ ...obj }));
                }
                // this.oldDataMaster = { ...data.master };
                // if (!_.isEmpty(data.detail)) this.oldDateDetail = data.detail.forEach(item => ({ ...item }));
                this.setState({
                    dataForm: {
                        master: !Config.isEmpty(dataMaster) ? dataMaster : this.state.dataForm.master,
                        detail: data.detail || []
                    },
                    detailInfoCancel: data.detail,
                    totalDetailInfoCancel: data.detail.length || 0,
                    loading: false
                });
            }
        });
    }

    checkValid = (name, val, rules = ["isRequired"]) => {
        return { name, rules, value: val };
    };


    setStateErrorText(objValue) {
        this.setState({ errorForm: { ...(this.state.errorForm ? this.state.errorForm : {}), ...objValue } });
        return Object.keys(objValue).length !== 0;
    }

    getCodeLangMaster = (keyName) => {
        const listMasterValueName = {
            ExchangeValue: "ERP_Chi_phi_huy",
            ProjectName: "ERP_Don_vi_chiu_phi",
            GuaranteeClause01U: "ERP_Ly_do_huy",
            GuaranteeDate: "ERP_Ngay_lap",
        };
        return listMasterValueName[keyName];
    }

    getCodeLangInventory = (keyName) => {
        const listDetailValues = {
            QuantityCancel: "ERP_So_luong_huy1",
            DescriptionU: "ERP_Ghi_chu",
        };
        return listDetailValues[keyName];
    }

    saveHistory = async (newData) => {
        let data = [];
        const { currentRowSelected } = this.props;
        const { master: newDataMaster = {}, detail: newDataDetail = [] } = newData;
        const { master: oldDataMaster = {}, detail: oldDataDetail = [] } = this.oldData;
        const detailFieldName = oldDataDetail.map(jaja => Object.keys(jaja))[0];
        //<===================ĐÂY LÀ LƯU LỊCH SỬ MASTER==================>
        const masterKey = Object.keys(newDataMaster);
        masterKey.forEach((value) => {
            if (newDataMaster[value] !== oldDataMaster[value]) {
                const descriptionVi = this.getCodeLangMaster(value);
                const descriptionEn = this.getCodeLangMaster(value);
                const oldValue = _.isNumber(oldDataMaster[value]) ? _.toString(oldDataMaster[value]) : oldDataMaster[value];
                const newValue = _.isNumber(newDataMaster[value]) ? _.toString(newDataMaster[value]) : newDataMaster[value];
                if (_.isEmpty(oldValue) && _.isEmpty(newValue)) return;
                let el = {};
                el.codeID = currentRowSelected.ContractID;
                el.formID = "W05F0001";
                el.type = "text";
                el.linkedTrans = "";
                el.oldValue = oldValue
                el.newValue = newValue
                el.description84 = Config.lang(descriptionVi, "vi");
                el.description01 = Config.lang(descriptionEn, "en");
                el.action = 1;
                el.hasURL = false;
                data.push(el);
            }
        });
        //<===================ĐÂY LÀ LƯU LỊCH SỬ MASTER==================>


        //<===================ĐÂY LÀ LƯU LỊCH SỬ DETAIL==================>
        newDataDetail.forEach((dataMoi) => {
            oldDataDetail.forEach(dataCu => {
                detailFieldName.forEach(fieldName => {
                    if (!_.isUndefined(dataMoi[fieldName]) && dataMoi.SalesItemID === dataCu.SalesItemID && dataMoi[fieldName] !== dataCu[fieldName]) {
                        const descriptionVi = this.getCodeLangInventory(fieldName);
                        const descriptionEn = this.getCodeLangInventory(fieldName);
                        const descriptionViCustom = descriptionVi === "ERP_Ghi_chu" ? Config.lang(descriptionVi, "vi") + "(" + Config.lang("ERP_Thong_tin_huy", "vi") + ")" : Config.lang(descriptionVi, "vi");
                        const descriptionEnCustom = descriptionEn === "ERP_Ghi_chu" ? Config.lang(descriptionVi, "en") + "(" + Config.lang("ERP_Thong_tin_huy", "en") + ")" : Config.lang(descriptionVi, "en");
                        if (_.isEmpty(descriptionVi) || _.isEmpty(descriptionEn)) return;
                        let el = {};
                        el.codeID = currentRowSelected.ContractID;
                        el.formID = "W05F0001";
                        el.type = "text";
                        el.linkedTrans = "";
                        el.oldValue = dataCu[fieldName];
                        el.newValue = dataMoi[fieldName];
                        el.description84 = `${descriptionViCustom} ${Config.lang("ERP_Hang_hoa", 'vi')} ${dataCu.InventoryID} ${dataCu.InventoryNameU}`;
                        el.description01 = `${descriptionEnCustom} ${Config.lang("ERP_Hang_hoa", 'en')} ${dataCu.InventoryID} ${dataCu.InventoryNameU}`;
                        el.action = 1;
                        el.hasURL = false;
                        data.push(el);
                    }
                });
            });
        });
        //<===================ĐÂY LÀ LƯU LỊCH SỬ DETAIL==================>
        if (data.length > 0) {
            const param = {
                attributes: JSON.stringify(data),
            };
            await this.props.w05f0001Actions.saveHistory(param, (error, data) => {
                this.setState({ formLoading: false, isSave: false });
                if (error) {
                    let message = error.message || Config.lang("ERP_Luu_lich_su_khong_thanh_cong");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    this.saveSuccessAction(); // XUất thông báo lưu thành công 
                }
            });
        } else { // Không Lưu lịch sử chỉ lưu Save
            this.saveSuccessAction(); // XUất thông báo lưu thành công 
        }
    }

    saveSuccessAction = () => {
        Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
        if (this.props.onCloseUpdateInfoCancelModal) this.props.onCloseUpdateInfoCancelModal(); // Đóng Modal
    }

    formatDateTimeObj = (dataObj = {}) => { // Format tất cả data có định dạng ngày trong Object
        if (_.isEmpty(dataObj)) return;
        Object.keys(dataObj).forEach(keyName => {
            if (dataObj[keyName] && Config.isValidDateTime(dataObj[keyName])) {
                dataObj[keyName] = moment(dataObj[keyName]).format("YYYY-MM-DD");
            }
        });
        return { dataObj };
    }

    formatDateTimeArray = (dataArray = []) => { // Format tất cả data có định dạng ngày trong Array
        if (_.isEmpty(dataArray)) return;
        const keyNameInObj = dataArray[0]; // KeyName
        dataArray.forEach(obj => {
            Object.keys(keyNameInObj).forEach(keyName => {
                if (obj[keyName] && Config.isValidDateTime(obj[keyName])) {
                    obj[keyName] = moment(obj[keyName]).format("YYYY-MM-DD");
                }
            });
        });
        return { dataArray };
    }

    checkValid = (name, val, rules = ["isRequired"]) => {
        return { name, rules, value: val };
    };

    saveCancel = () => {
        if (this.dataGrid) this.dataGrid.instance.saveEditData();
        const { currentRowSelected } = this.props;
        const { master, detail } = this.state.dataForm;
        const { ExchangeValue, ProjectName } = this.state.dataForm.master;
        const { dataObj: dataMaster = {} } = this.formatDateTimeObj(master);
        const fExchangeValue = this.checkValid("ExchangeValue", ExchangeValue ? ExchangeValue : _.toString(ExchangeValue));
        const fProjectName = this.checkValid("ProjectName", ProjectName);
        const validateForm = MForm.formValidation([
            fExchangeValue,
            fProjectName
        ]);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            if (_.isUndefined(dataMaster.ContractID) && currentRowSelected.ContractID) {
                dataMaster.ContractID = currentRowSelected.ContractID; // Thêm ContractID vào params
            }
            const params = {
                Language: this.Language,
                master: JSON.stringify(dataMaster),
                detail: JSON.stringify(detail)
            };
            this.setState({ loading: true });
            this.props.w05f0001Actions.saveCancel(params, (error, data) => {
                if (error) {
                    this.setState({ loading: false });
                    const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    this.setState({
                        loading: false
                    }, () => {
                        this.saveHistory(this.state.dataForm); // Lưu lịch sử và xuất thông báo
                    });
                }
            });
        }
    }

    getCboProject = () => {
        const params = {
            Language: this.Language
        };
        this.props.w05f0001Actions.getCboProject(params, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    }

    onChangeMaster = (fieldName = "", data, type = "string") => {
        if (!fieldName) return;
        const dateFieldName = ["GuaranteeDate"]; // Field Ngày tháng
        let value = "";
        const { dataForm } = this.state;
        if (dateFieldName.includes(fieldName)) {
            value = data.value;
        } else if (fieldName === "ExchangeValue") {
            value = !Config.isEmpty(data, true) ? _.toNumber(data) : data;
        } else {
            value = type === "number" ? _.toNumber(data.target.value) : data.target.value;
        }
        dataForm.master[fieldName] = value;
        this.setState({
            dataForm,
            // btnSaveStatus: true
        });
    }

    onChangeComboMaster = (fieldName, e) => {
        let fieldID = "";
        let valueID = "";
        let valueName = "";
        const { dataForm } = this.state;
        const value = _.get(e, "value", _.get(e, "target.value", e ? e : ""));
        if (!_.isNull(value)) {
            valueID = e.value;
            const { dataCboProject } = this.props;
            switch (fieldName) {
                case "ProjectName": {
                    fieldID = "ProjectID";
                    const dataStatusChosen = dataCboProject.find(item => item.ProjectID === e.value);
                    valueName = dataStatusChosen.ProjectName;
                    break;
                }
                default:
                    break;
            }
        }
        dataForm.master[fieldID] = valueID;
        dataForm.master[fieldName] = valueName;
        this.setState({
            dataForm,
            // btnSaveStatus: true
        });
    }


    renderCancelInfo = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const { InventoryID = "", InventoryNameU = "", PackingMethodName = "", PackingTypeName = "",
            UnitID = "", DescriptionU = "", QuantityCancel = 0, TotalQuantity = 0, Quantity = 0 } = data;
        // const quantityCancelValue = Number(TotalQuantity) - Number(Quantity);
        return (
            <Row>
                <Col xs={12} sm={6} md={6} lg={6} style={{ minHeight: 70 }}>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '32%', marginRight: 5 }}><Label style={{ fontWeight: 600 }}>{Config.lang("ERP_Hang_hoa")}:</Label></span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${InventoryID} - ${InventoryNameU}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '32%', marginRight: 5 }}><Label style={{ fontWeight: 600 }}>{Config.lang("ERP_Quy_cach_dong_goi")}:</Label></span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${PackingMethodName || ""}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '32%', marginRight: 5 }}><Label style={{ fontWeight: 600 }}>{Config.lang("ERP_Loai_bao_dong_goi")}:</Label></span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${PackingTypeName || ""}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '32%', marginRight: 5 }}><Label style={{ fontWeight: 600 }}>{Config.lang("ERP_Nhan_mac")}:</Label></span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${data.MarkingName || ""}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <span style={{ width: '32%', marginRight: 5 }}>{Config.lang("ERP_Don_vi_tinh")}:</span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${UnitID}`}</span>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} style={{ minHeight: 70 }}>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '36%' }}>{Config.lang("ERP_Tong_so_luong")}:</span>
                        <span className={classes.ellipsis} style={{ width: '64%' }}>{`${Config.numberFormat(TotalQuantity)}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '36%' }}>{Config.lang("ERP_So_luong_da_trien_khai")}:</span>
                        <span className={classes.ellipsis} style={{ width: '64%' }}>{`${Quantity}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '36%' }}>{Config.lang("ERP_So_luong_huy1")}:</span>
                        <span className={classes.ellipsis} style={{ width: '64%' }}>
                            <NumberFormatCustom
                                fullWidth
                                margin={"none"}
                                isNumericString={true}
                                thousandSeparator={true}
                                value={QuantityCancel}
                                className={classes.inputField}
                                onChange={(e) => this.onChangeInputDetail("QuantityCancel", _.toNumber(e), data)}
                            />
                        </span>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <span style={{ width: '36%' }}>{Config.lang("ERP_Ghi_chu")}:</span>
                        <span className={classes.ellipsis} style={{ width: '64%' }}>
                            <TextBox
                                name={DescriptionU}
                                className={classes.textBoxField}
                                stylingMode={"outlined"}
                                onChange={(value) => this.onChangeTextBoxDetail("DescriptionU", value, data)}
                                defaultValue={DescriptionU} />
                        </span>
                    </div>
                </Col>
            </Row>
        )
    }

    onChangeTextBoxDetail = (name, e, currentRow) => {
        const { detailInfoCancel } = this.state;
        if (!_.isEmpty(detailInfoCancel) && e && currentRow) {
            detailInfoCancel.forEach(item => {
                if (item.SalesItemID === currentRow.SalesItemID) {
                    item[name] = e.event.target.value;
                    return;
                }
            });
            // this.setState({ btnSaveStatus: true });
        }
    }

    onChangeInputDetail = (columnName = "", e, currentRow = {}) => {
        if (_.isEmpty(currentRow) || _.isEmpty(columnName)) return;
        currentRow[columnName] = e;
        // this.setState({ btnSaveStatus: true });
        // let value = e;
        // const { master, detail } = this.state.dataForm;
        // const numberFormatColumn = ["QuantityCancel"];
        // if (!numberFormatColumn.includes(columnName)) {
        //     value = e.target.value;
        // }
        // if (!_.isEmpty(detail)) {
        //     detail.forEach(obj => {
        //         if (obj.SalesItemID === currentRow.SalesItemID) {
        //             obj[columnName] = value;
        //         }
        //     })
        // }
        // this.setState({
        //     master,
        //     detail,
        //     btnSaveStatus: true
        // });
    }

    render() {
        const { onCloseUpdateInfoCancelModal, dataCboProject } = this.props;
        const { errorForm, loading, detailInfoCancel, dataForm, totalDetailInfoCancel } = this.state;
        const { ProjectID, ProjectName, ExchangeValue, GuaranteeDate, GuaranteeClause01U } = dataForm.master;
        // const ExchangeValueStr = _.isNumber(ExchangeValue) || _.isNull(ExchangeValue) ? _.toString(ExchangeValue) : ExchangeValue;
        return (
            <Modal
                open={true}
                maxWidth={"md"}
                fullWidth={true}
                position={"center"}
            >
                <Modal.Title disableTypography>
                    <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                        <div className={"display_row align-center"}>
                            <Typography variant={"h6"} className={"mgr10 text-uppercase"}>{Config.lang("ERP_Cap_nhat_thong_tin_huy")}</Typography>
                        </div>
                        <div>
                            <IconButton
                                disabled={false}
                                aria-label={"save"}
                                size={"small"}
                                className={"mgr10"}
                                onClick={() => this.saveCancel()}
                            >
                                <Icons name={"save_filled"} />
                            </IconButton>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={() => onCloseUpdateInfoCancelModal()}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Content>
                    <Row>
                        <Col xs={12} sm={6} md={6} lg={6} style={{ height: 70 }}>
                            <NumberFormatCustom
                                fullWidth
                                required={true}
                                isNumericString={true}
                                thousandSeparator={true}
                                value={ExchangeValue}
                                label={Config.lang("ERP_Chi_phi_huy")}
                                error={_.isEmpty(ExchangeValue) && _.get(errorForm, "ExchangeValue", false)}
                                onChange={(e) => this.onChangeMaster("ExchangeValue", e, "number")}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} style={{ height: 70 }}>
                            <Combo
                                shrink={true}
                                required={true}
                                showClearButton={true}
                                margin={"normal"}
                                valueExpr={"ProjectID"}
                                stylingMode={"underlined"}
                                displayExpr={"ProjectName"}
                                label={Config.lang("ERP_Don_vi_chiu_phi")}
                                value={ProjectID}
                                disabled={loading}
                                dataSource={dataCboProject}
                                error={_.isEmpty(ProjectName) && _.get(errorForm, "ProjectName", false)}
                                onValueChanged={(e) => this.onChangeComboMaster("ProjectName", e)}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} style={{ height: 70 }}>
                            <DateBoxPicker
                                height={30}
                                shrink={true}
                                useMaskBehavior={true}
                                showClearButton={true}
                                width={"100%"}
                                margin={"normal"}
                                stylingMode={"underlined"}
                                label={Config.lang("ERP_Ngay_lap")}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { marginTop: -7 }
                                }}
                                disabled={loading}
                                value={GuaranteeDate}
                                onValueChanged={e => this.onChangeMaster("GuaranteeDate", e)}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} style={{ height: 70 }}>
                            <TextField
                                fullWidth
                                margin={"normal"}
                                variant={"standard"}
                                label={Config.lang("ERP_Ly_do_huy")}
                                disabled={loading}
                                value={GuaranteeClause01U}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => this.onChangeMaster("GuaranteeClause01U", e)}
                            />
                        </Col>
                    </Row>

                    <React.Fragment>
                        <GridContainer
                            reference={ref => {
                                this.dataGrid = ref;
                            }}
                            typePaging={"default"}
                            elementAttr={{ style: 'margin-top: 30px' }}
                            itemPerPage={3}
                            skipPerPage={0}
                            height={380}
                            showBorders={true}
                            showRowLines={true}
                            pagerFullScreen={false}
                            showColumnLines={false}
                            showColumnHeaders={false}
                            dataSource={detailInfoCancel}
                            totalItems={totalDetailInfoCancel}
                            loading={loading}
                        >
                            <Column cellRender={this.renderCancelInfo} width={"100%"} />
                        </GridContainer>
                    </React.Fragment>

                </Modal.Content>
            </Modal>
        );
    }
}

W05F0001UpdateInfoCancel.propsTypes = {
    currentRowSelected: PropTypes.obj,
    onCloseUpdateInfoCancelModal: PropTypes.func,
};

export default compose(connect(state => ({
    dataCboProject: state.W05F0001.dataCboProject,
}), dispatch => ({
    w05f0001Actions: bindActionCreators(w05f0001Actions, dispatch)
})), withStyles(styles))(W05F0001UpdateInfoCancel);