import { Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0001Actions from "../../../../redux/W0X/W05F0001/W05F0001_actions";

class W05F0001Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: "all",
        }
    }

    renderTab = () => {
        const { funcGetEStatus, tabStatus } = this.props;
        return tabStatus && tabStatus.map(({ EStatus, EStatusName, total }, i) => (
            <Tab
                key={i}
                value={EStatus}
                label={String(Config.lang(EStatusName)) === "Tất cả" ? `${Config.lang('ERP_Tat_ca')} (${total})` : `${Config.lang(EStatusName)} (${total})`}
                onClick={() => funcGetEStatus(EStatus)}
            />
        ));
    };

    handleChanged = (event, indexTab) => {
        this.setState({ indexTab });
    };

    render() {
        const { indexTab } = this.state;
        return (
            <Tabs
                indicatorColor={"primary"}
                textColor={"primary"}
                value={indexTab}
                onChange={this.handleChanged}
            >
                {this.renderTab()}
            </Tabs>
        );
    }
}

W05F0001Tabs.propsTypes = {
    tabStatus: PropTypes.array,
    funcGetEStatus: PropTypes.func
};

export default compose(
    connect(null,
        dispatch => ({
            w05f0001Actions: bindActionCreators(w05f0001Actions, dispatch)
        })))(W05F0001Tabs);
