/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 06/17/2020
 * @Example 
 */
import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from "lodash";
import PropTypes from "prop-types";
import { Component, default as React } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import Config from "../../../../config/index";
import { TextField, Combo } from "../../../common/form-material";
import W91F0001 from "../../W09/W91F0001/W91F0001";

class W05F0002ObjectCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showW91F0001: false,
    };
    this.isGObjectID = false;
  }

  hideW91F0001 = (data) => {
    const { formData, onChange, onChangeCbo, loadApi } = this.props;
    const {ObjectID, GObjectID} = formData;
    if (data) {
      this.setState({ showW91F0001: false });
      if (this.isGObjectID) {
        const objectDataList = {GObjectID: "ObjectID", GObjectName: "ObjectNameU", GObjectTypeID: "ObjectTypeID"};
        if (data["ObjectID"] === ObjectID) {
          Config.popup.show("INFO", Config.lang("Don_vi_uy_thac_khong_duoc_giong_voi_khach_hang_da_chon"));
          return false;
        }
        onChangeCbo(data["ObjectNameU"], "GObjectName");
        for(let item of Object.keys(objectDataList)) {
          const key = objectDataList[item];
          onChange(data[key], item);
        }
        this.isGObjectID = false;
      } else {
        if (data["ObjectID"] === GObjectID) {
          Config.popup.show("INFO", Config.lang("Don_vi_uy_thac_khong_duoc_giong_voi_khach_hang_da_chon"));
          return false;
        }
        loadApi("getCboBankAccount", { ObjectTypeID: data.ObjectTypeID, ObjectID: data.ObjectID }, true);
        const objectDataList = ["NotesU", "ObjectAddressU", "VATNo", "BankAccountNo", "TelNo", "FaxNo", "ObjectID", "ObjectNameU"];
        onChangeCbo(data["ObjectNameU"], "ObjectNameU");
        for(let item of objectDataList) {
          onChange(data[item], item);
        }
      }
    }
  };

  render() {
    const {
      onChange,
      loading,
      errorForm,
      formData,
      contractTypesData,
      getCboBankAccount
    } = this.props;
    const { showW91F0001 } = this.state;
    let {
      NotesU,
      ObjectNameU,
      ObjectAddressU,
      VATNo,
      BankAccountNo,
      TelNo,
      FaxNo,
      ObjectID,
      GObjectName
    } = formData;
    const iEmptyObjectID = _.isEmpty(ObjectID);
    const { IsGuarantee } = contractTypesData || {};
    return (
      <div style={{ width: "100%" }}>
        <Row>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              error={iEmptyObjectID && _.get(errorForm, "ObjectID", false)}
              label={Config.lang("ERP_Khach_hang")}
              variant={"standard"}
              margin={"normal"}
              required={true}
              value={ObjectNameU}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                readOnly: true
              }}
              disabled={loading}
              fullWidth
              onClick={() => this.setState({ showW91F0001: true })}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <ExpandMoreIcon style={{ color: '#687799', fontSize: '24px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Col>
          {showW91F0001 && <W91F0001 showW91F0001={showW91F0001} ObjectTypeID={"KH"}
            onCloseModal={() => { this.setState({ showW91F0001: false }) }}
            mode={1} onHide={(data) => {
              this.hideW91F0001(data);
            }}
            reLoadPage
          />}
          <Col xs={12} sm={12} md={8} lg={9}>
            <TextField
              label={Config.lang("ERP_Dia_chi")}
              variant={"standard"}
              margin={"normal"}
              value={ObjectAddressU}
              disabled={loading}
              inputProps={{
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("ERP_Ma_so_thue")}
              variant={"standard"}
              margin={"normal"}
              value={VATNo}
              disabled={loading}
              inputProps={{
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <Combo
              label={Config.lang("ERP_So_tai_khoan")}
              dataSource={getCboBankAccount}
              displayExpr={"BankAccountNo"}
              valueExpr={"BankAccountNo"}
              stylingMode={"underlined"}
              margin={"normal"}
              disabled={loading}
              value={BankAccountNo}
              shrink={true}
              onValueChanged={(e) => onChange(e.value, "BankAccountNo")}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("ERP_So_dien_thoai")}
              variant={"standard"}
              margin={"normal"}
              value={TelNo}
              disabled={loading}
              inputProps={{
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("ERP_So_Fax")}
              variant={"standard"}
              margin={"normal"}
              value={FaxNo}
              disabled={loading}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                readOnly: true
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          {IsGuarantee === 1 && <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("Don_vi_uy_thac")}
              variant={"standard"}
              margin={"normal"}
              value={GObjectName}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                readOnly: true
              }}
              disabled={loading}
              fullWidth
              onClick={() => {
                this.setState({ showW91F0001: true });
                this.isGObjectID = true;
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <ExpandMoreIcon style={{ color: '#687799', fontSize: '24px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Col>}
          <Col xs={12} sm={12} md={IsGuarantee === 1 ? 8 : 12} lg={IsGuarantee === 1 ? 9 : 12}>
            <TextField
              label={Config.lang("ERP_Ghi_chu")}
              variant={"standard"}
              margin={"normal"}
              disabled={loading}
              value={NotesU}
              InputLabelProps={{
                shrink: true
              }}
              onChange={e => onChange(e, "NotesU")}
              fullWidth
            />
          </Col>
        </Row>
      </div>
    );
  }
}

W05F0002ObjectCustomer.propTypes = {
  loading: PropTypes.bool,
  mode: PropTypes.string,
  formData: PropTypes.object,
  errorForm: PropTypes.object,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  onChangeCbo: PropTypes.func,
};

export default compose(
  connect(state => ({
    getCboObject: state.W05F0002.getCboObject,
    getCboBankAccount: state.W05F0002.getCboBankAccount
  })))(W05F0002ObjectCustomer);